const columns = [
    {
        name: "nome",
        label: "Nome",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "cpf",
        label: "CPF",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "rg",
        label: "RG",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "tel",
        label: "Telefone",
        options: {
            filter: false,
            sort: false,
        }
    },
    {
        name: "cidade",
        label: "Cidade",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "estado",
        label: "Estado",
        options: {
            filter: true,
            sort: true,
        }
    },
    // {
    //     name: "status",
    //     label: "Status",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     }
    // },
    {
        name: "action",
        label: "Ações",
        options: {
            filter: false,
            sort: false,
        }
    },
];

const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    viewColumns: false,
};


export { columns, options }