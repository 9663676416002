import React from 'react';
import * as Styled from './Styled'

function CardScreen({ data }) {
  return <Styled.Container container>
    {
      data.map((item, index) => {
        return (
          <Styled.Card key={item.nome}>
            <Styled.Row>
              <Styled.Column className='item'>
                <p>Nome</p>
                <b>{item.nome}</b>
              </Styled.Column>
              <Styled.Column className='item'>
                <p>CPF</p>
                <b>{item.cpf}</b>
              </Styled.Column>
              <Styled.Column className='item'>
                <p>RG</p>
                <b>{item.rg}</b>
              </Styled.Column>
            </Styled.Row>
            <hr />
            <Styled.Row>
              <Styled.Column className='item'>
                <p>Conselho</p>
                <b>{item.conselho}</b>
              </Styled.Column>
              <Styled.Column className='item'>
                <p>Registro</p>
                <b>{item.registro}</b>
              </Styled.Column>
              <Styled.Column className='item'>
                <p>Data nascimento</p>
                <b>{item.data_nascimento}</b>
              </Styled.Column>
            </Styled.Row>
            <hr />
            <Styled.Row>
              <Styled.Column className='item'>
                <p>Cidade-UF</p>
                <b>{item.cidade_uf}</b>
              </Styled.Column>
              <Styled.Column className='item'>
                <p>Data de cadastro</p>
                <b>{item.data_cadastro}</b>
              </Styled.Column>
              <Styled.Column className='item'>
                <p>ID da solicitação</p>
                <b>{item.id_solicitacao}</b>
              </Styled.Column>
            </Styled.Row>
            <hr />
            {item.action}
          </Styled.Card>
        )
      })
    }
  </Styled.Container>;
}

export default CardScreen;
