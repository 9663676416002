import React from 'react';
import {
    View,
    EditOrange,
    OrdemServico
} from '../../../../../assets/imgs/icons'

function Actions({ setModal }) {
    return <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: 5
    }}>
        <img
            src={View}
            alt="Visualizar"
            style={{ cursor: 'pointer' }}
            onClick={() => setModal(prevState => { return { ...prevState, name: 'View', status: true } })}
        />

        <img
            src={OrdemServico}
            style={{ cursor: 'pointer' }}
            alt="Ordem de Serviço"
            // onClick={() => setModal(prevState => { return { ...prevState, name: 'Order of Service', status: true } })}
        />

        <img
            src={EditOrange}
            style={{ cursor: 'pointer' }}
            alt="Editar"
            onClick={() => setModal(prevState => { return { ...prevState, name: 'Edit', status: true } })}
        />
    </div>;
}

export default Actions;
