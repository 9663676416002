import React from 'react';
import MUIDataTable from "mui-datatables";
import { columns, options } from '../data'

function TableScreen({ data }) {

    return <MUIDataTable
        className="table-styles"
        data={data}
        columns={columns}
        options={options}
    />;
}

export default TableScreen;
