import React, { 
    useState, 
    useEffect, 
    useCallback, 
    useMemo, 
    useRef 
} from 'react';
import MUIDataTable from "mui-datatables";
import { useNavigate } from 'react-router-dom';
import Actions from '../Actions'
import { ListApprovedProfessionalsRequest } from '../../../../../core/grpc/generated/professionalProfile_pb'
import { listApprovedProfessionalsService } from '../../../../../core/services/profissionalService'
import { columns, options } from '../data'

function TableScreen({ setScreen, setView }) {
    const history = useNavigate();
    const mountedRef = useRef(true)
    const [data, setData] = useState([])
    const arr = useMemo(() => [],[])

    const list = useCallback(async () => {
        var request = new ListApprovedProfessionalsRequest();
        const response = await listApprovedProfessionalsService(request)
        console.log(response)
        const { success, errormessage } = response

        if (success) {
            response.professionalsList.forEach((item, i) => {
                arr[i] = {
                    nome: item.firstname,
                    cpf: item.socialsecuritynumber,
                    rg: item.identitynumber,
                    tel: item.socialsecuritynumber,
                    cidade: item.address.city,
                    estado: item.address.state,
                    status: item.isApproved === 0 ? 'Inativo' : 'Ativo',
                    action: <Actions setScreen={setScreen} setView={setView} />
                }
            })
            if(mountedRef.current){
                setData(arr)
            }
        } else {
            if (errormessage === "Não autorizado") {
                localStorage.removeItem("token")
                localStorage.removeItem("phone")
                localStorage.removeItem("email")
                localStorage.removeItem("date")
                history('/login')
            }
        }
    }, [arr, history, setScreen, setView])

    useEffect(() => {
        list()
        return () => {
            mountedRef.current = false
        }
        // eslint-disable-next-line
    }, []);

    return <MUIDataTable
        className="table-styles"
        data={data}
        columns={columns}
        options={options}
    />;
}

export default TableScreen;
