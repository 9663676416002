import React, { useState, useEffect } from 'react'
import * as Styled from './Styled'
import Providers from '../Providers'
import MUIDataTable from "mui-datatables"
import Actions from './Actions'
import Modals from './Modals'
import {
    columnsAPagar,
    columnsAReceber,
    columnsComissoesAPagar,
    options
} from './data'
import { Back } from '../../../../assets/imgs/icons'

const Home = () => {
    const [screen, setScreen] = useState('Home')
    const [navigatorTable, setNavigatorTable] = useState('Contas a pagar')
    const [modal, setModal] = useState({
        status: false,
        id: undefined,
        name: '',
    })
    const dataAPagar = [
        {
            favorecido: <b>CASA DO MATERIAL DE CONSTRUÇÃO</b>,
            natureza_favorecido: "Fornecedor",
            forma_pagamento: "Boleto",
            n_documento: "123431235",
            vencimento: "05/10/2021",
            parcela: "1/3",
            valor: "R$ 234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            favorecido: <b>RESTAURANTE DOCE SABOR</b>,
            natureza_favorecido: "Fornecedor",
            forma_pagamento: "Boleto",
            n_documento: "123431235",
            vencimento: "05/10/2021",
            parcela: "única",
            valor: "R$ 234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            favorecido: <b>PABLO GARCIA</b>,
            natureza_favorecido: "Colaborador",
            forma_pagamento: "PIX",
            n_documento: "123431235",
            vencimento: "05/10/2021",
            parcela: "Mensal",
            valor: "R$ 2.234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            favorecido: <b>CRISTINA DAMACENO</b>,
            natureza_favorecido: "Comissão",
            forma_pagamento: "TED",
            n_documento: "123431235",
            vencimento: "05/10/2021",
            parcela: "única",
            valor: "R$ 1.234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            favorecido: <b>LUIZA MARINA DA SILVA</b>,
            natureza_favorecido: "Colaborador",
            forma_pagamento: "PIX",
            n_documento: "123431235",
            vencimento: "05/10/2021",
            parcela: "mensal",
            valor: "R$ 2.234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            favorecido: <b>RICARDO LINHARES DE ARAÚJO</b>,
            natureza_favorecido: "Colaborador",
            forma_pagamento: "DOC",
            n_documento: "123431235",
            vencimento: "05/10/2021",
            parcela: "mensal",
            valor: "R$ 2.234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
    ]

    const dataAReceber = [
        {
            membro_alianca: <b>CLÍNICA CARLOS BATISTA</b>,
            natureza_membro: "Empresa",
            forma_pagamento: "Boleto",
            n_documento: "123431235",
            vencimento: "05/10/2021",
            licenca: "Mensal",
            valor: "R$ 234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            membro_alianca: <b>CLÍNICA DOS OLHOS</b>,
            natureza_membro: "Empresa",
            forma_pagamento: "Boleto",
            n_documento: "123431235",
            vencimento: "05/10/2021",
            licenca: "Mensal",
            valor: "R$ 234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            membro_alianca: <b>HOSPITAL DO CORAÇÃO</b>,
            natureza_membro: "Empresa",
            forma_pagamento: "PIX",
            n_documento: "123431235",
            vencimento: "05/10/2021",
            licenca: "Mensal",
            valor: "R$ 2.234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            membro_alianca: <b>MATERNIDADE CÂNDIDA VARGAS</b>,
            natureza_membro: "Empresa",
            forma_pagamento: "TED",
            n_documento: "123431235",
            vencimento: "05/10/2021",
            licenca: "Mensal",
            valor: "R$ 1.234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            membro_alianca: <b>LABORATÓRIO MARLUCE VASCONCELOS</b>,
            natureza_membro: "Empresa",
            forma_pagamento: "PIX",
            n_documento: "123431235",
            vencimento: "05/10/2021",
            licenca: "Mensal",
            valor: "R$ 2.234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            membro_alianca: <b>PEDRO LINS CARTAXO</b>,
            natureza_membro: "Profissional",
            forma_pagamento: "PIX",
            n_documento: "123431235",
            vencimento: "05/10/2021",
            licenca: "Mensal",
            valor: "R$ 2.234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
    ]

    const dataComissoesAPagar = [
        {
            colaborador: <b>PAULO AMORIM</b>,
            natureza_parceiro: "Comercial",
            forma_pagamento: "Pix",
            n_proposta: "123431235",
            vencimento: "05/10/2021",
            periodicidade: "Mensal",
            valor: "R$ 234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            colaborador: <b>VIDAL DOS SANTOS</b>,
            natureza_parceiro: "Comercial",
            forma_pagamento: "Pix",
            n_proposta: "123431235",
            vencimento: "05/10/2021",
            periodicidade: "Mensal",
            valor: "R$ 234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            colaborador: <b>MARIA CLARA REBOUÇAS</b>,
            natureza_parceiro: "Comercial",
            forma_pagamento: "Pix",
            n_proposta: "123431235",
            vencimento: "05/10/2021",
            periodicidade: "Parcela única",
            valor: "R$ 2.234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            colaborador: <b>VÂNIA ALENCAR</b>,
            natureza_parceiro: "Comercial",
            forma_pagamento: "Pix",
            n_proposta: "123431235",
            vencimento: "05/10/2021",
            periodicidade: "Mensal",
            valor: "R$ 1.234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            colaborador: <b>MÁRCIO DOS SANTOS</b>,
            natureza_parceiro: "Comercial",
            forma_pagamento: "Pix",
            n_proposta: "123431235",
            vencimento: "05/10/2021",
            periodicidade: "Parcela única",
            valor: "R$ 2.234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
        {
            colaborador: <b>VICTOR BARBOSA</b>,
            natureza_parceiro: "Comercial",
            forma_pagamento: "Pix",
            n_proposta: "123431235",
            vencimento: "05/10/2021",
            periodicidade: "Parcela única",
            valor: "R$ 2.234,00",
            actions: <Actions navigatorTable={navigatorTable} setModal={setModal} />
        },
    ]

    const [data, setData] = useState(dataAPagar)
    const [column, setColumn] = useState(columnsAPagar)

    useEffect(() => {
        if (navigatorTable === "Contas a pagar") {
            setData(dataAPagar)
            setColumn(columnsAPagar)
        }
        if (navigatorTable === "Contas a Receber") {
            setData(dataAReceber)
            setColumn(columnsAReceber)
        }
        if (navigatorTable === "Comissões a Pagar") {
            setData(dataComissoesAPagar)
            setColumn(columnsComissoesAPagar)
        }
        // eslint-disable-next-line
    }, [navigatorTable]);

    const ModalBody = () => (
        <Styled.Modal>
            <Modals modal={modal} data={data} setModal={setModal} />
        </Styled.Modal>
    );


    return <Styled.Container>
        <Styled.Header className='gap'>
            <Styled.Row>
                <Styled.Row>
                    {screen !== "Home" && <img src={Back} alt="back" onClick={() => setScreen('Home')} />}
                </Styled.Row>
                <h1>Cockpit Financeiro</h1>
            </Styled.Row>
            <Styled.Row>
                <Styled.Button className='green'>REMESSAS E RETORNOS</Styled.Button>
                <Styled.Button className='orange' onClick={() => setScreen('Fornecedores')}>FORNECEDORES</Styled.Button>
                <Styled.Button className='greeninativo'>COLABORADORES</Styled.Button>
                <Styled.Button className='red'>CONTABILIDADE</Styled.Button>
                <Styled.Button className='darklighter'>RELATÓRIOS</Styled.Button>
            </Styled.Row>
        </Styled.Header>
        <Styled.ContainerCard container item>
            <Styled.Card item xs={6} lg={2}>
                <b>A PAGAR ESTE MÊS</b>
                <p>R$ 145.065,98</p>
            </Styled.Card>
            <Styled.Card item xs={6} lg={2}>
                <b>A RECEBER ESTE MÊS</b>
                <p>R$ 256.000,56</p>
            </Styled.Card>
            <Styled.Card item xs={6} lg={2}>
                <b>PAGO ESTE MÊS</b>
                <p>R$ 45.000,00</p>
            </Styled.Card>
            <Styled.Card item xs={6} lg={2}>
                <b>RECEBIDO ESTE MÊS</b>
                <p>R$ 132.045,00</p>
            </Styled.Card>
            <Styled.Card item xs={6} lg={2}>
                <b>NOTAS A GERAR</b>
                <p>145</p>
            </Styled.Card>
            <Styled.Card item xs={6} lg={2}>
                <b>COMISSÕES A PAGAR</b>
                <p>R$ 23.545,50</p>
            </Styled.Card>
        </Styled.ContainerCard>
        {screen === "Home" && <><Styled.BoxBtnTable>
            <div className='row'>
                <Styled.Button
                    className={navigatorTable === 'Contas a pagar' ? 'active' : 'disable'}
                    onClick={() => setNavigatorTable('Contas a pagar')}
                >
                    Contas a Pagar <b>[32]</b>
                </Styled.Button>
                <Styled.Button
                    className={navigatorTable === 'Contas a Receber' ? 'active' : 'disable'}
                    onClick={() => setNavigatorTable('Contas a Receber')}
                >
                    Contas a Receber <b>[4]</b>
                </Styled.Button>
                <Styled.Button
                    className={navigatorTable === 'Comissões a Pagar' ? 'active' : 'disable'}
                    onClick={() => setNavigatorTable('Comissões a Pagar')}
                >
                    Comissões a Pagar <b>[14]</b>
                </Styled.Button>
            </div>
            {navigatorTable === 'Contas a pagar' && <Styled.Button className='btnNew red'>
                NOVA DESPESA
            </Styled.Button>}
            {navigatorTable === 'Contas a Receber' && <Styled.Button className='btnNew green'>
                NOVA RECEITA
            </Styled.Button>}
        </Styled.BoxBtnTable>
            <MUIDataTable
                title={navigatorTable.toUpperCase()}
                className="table-styles"
                data={data}
                columns={column}
                options={options}
            /></>}
        {screen === "Fornecedores" && <Providers />}
        {modal.status && <ModalBody />}
    </Styled.Container>;
};

export default Home;
