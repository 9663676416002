import React from 'react';
import * as Styled from './Styled'
import {
  View,
  Negar,
  Edit
} from '../../../../../assets/imgs/icons/'

function Actions({ setModal, idX }) {
  return <Styled.Row>
    <Styled.Icon src={View} alt="view" className="eye" onClick={() => setModal({
      status: true,
      id: idX,
      name: 'View',
    })} />
    <Styled.Icon src={Negar} alt="negar" className="check" onClick={() => alert("function negar")} />
    <Styled.Icon src={Edit} alt="edit" className="edit" onClick={() => setModal({
      status: true,
      id: idX,
      name: 'Edit'
    })} />
  </Styled.Row>;
}

export default Actions;
