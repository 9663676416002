import styled from 'styled-components'
import Grid from '@mui/material/Grid';

const Container = styled(Grid)`
  width: 98%;
  margin: 0 auto;

  .red{
    border: 2px solid #EB5757;
  }
  .yellow{
    border: 2px solid #F2994A;
  }
  .gray{
    border: 2px solid #E0E0E0;
  }
  .mt-15{
    margin-top: 15px;
  }
  .mt-10{
    margin-top: 10px;
  }
  .shadow{
    box-shadow: 0 2px 5px rgb(0 0 0 / 0.1);
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 15px;
  border-radius: 8px;
  
  h1{
    font-size: 15px;
    text-decoration:none;
    margin: 0px !important;
    margin-bottom: 10px !important;
  }
  p{
    font-size: 14px;
  }
  .mb-2{
    margin-bottom: 2px !important;
  }
  .mt-8{
    margin-top: 8px !important;
  }
`

const CardOcorrencia = styled.div`
  display: flex;
  background-color: var(--main-lighter-paciente);
  flex-direction: column;
  width: 95%;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  
  h1{
    color: white;
    font-size: 15px;
    text-decoration:none;
    margin: 0px !important;
    margin-bottom: 10px !important;
  }
`

const H1 = styled.h1`
  font-size: 16px;
  text-decoration: none !important;
  text-transform: uppercase;
`

const H1Historico = styled.h1`
  font-size: 14px !important;
  font-weight: bolder !important;
  color: var(--main-light);
  padding-top: 7px;
`

const Btn = styled.button`
  font-size: 12px;
  background-color: black;
  color: white;
  width: max-content;
  border: none;
  border-radius: 15px;
  padding: 2px 10px;
  margin-left: auto;
`

const Row = styled.div`
  display: flex;
`

export { Container, H1, H1Historico, Card, CardOcorrencia, Btn, Row }