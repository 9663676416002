const dataLicensePayments = [
    { data: '10/10/2021', valor: 'R$ 239,90', status: 'a vencer' },
    { data: '10/08/2021', valor: 'R$ 239,90', status: 'Pago' },
    { data: '10/07/2021', valor: 'R$ 239,90', status: 'Pago' },
    { data: '10/07/2021', valor: 'R$ 239,90', status: 'Pago' },
]

const dataRecentServices = [
    { data: '21/08/2021', paciente: 'Talles Carrelo', status: 'Concluído' },
    { data: '21/08/2021', paciente: 'Talles Carrelo', status: 'Concluído' },
    { data: '21/08/2021', paciente: 'Talles Carrelo', status: 'Concluído' },
    { data: '21/08/2021', paciente: 'Talles Carrelo', status: 'Concluído' },
    { data: '21/08/2021', paciente: 'Talles Carrelo', status: 'Concluído' },
]

const dataServicesAvailable = [
    { nome: 'Ultrasonografia' },
    { nome: 'Mamografia' },
    { nome: 'RaioX' },
    { nome: 'Ressonância Magnética' },
]

const dataRegisteredProfessionals = [
    { nome: 'Carla Delarus', cargo: 'Secretária' },
    { nome: 'Pablo Arruda', cargo: 'Pediatra' },
    { nome: 'Renato Alberto', cargo: 'Nutricionista' },
    { nome: 'Camilla Rezende', cargo: 'Ginecologista' },
    { nome: 'André Arruda', cargo: 'Pediatra' },
]

const dataNumbersStatistics = [
    { servico: 'RESSONÂNCIA', agendamentos: '2134', taxacancelamento: '12%', tmedio_atendimento: '12:45s', tmedio_espera: '12:45s', avaliacoes: '123', nota_media: '5' },
    { servico: 'TOMOGRAFIA', agendamentos: '23432', taxacancelamento: '12%', tmedio_atendimento: '12:45s', tmedio_espera: '12:45s', avaliacoes: '1234', nota_media: '5' },
    { servico: 'RAIOX', agendamentos: '12343', taxacancelamento: '12%', tmedio_atendimento: '12:45s', tmedio_espera: '12:45s', avaliacoes: '1234', nota_media: '5' },
    { servico: 'ULTRASSONOGRAFIA', agendamentos: '123434', taxacancelamento: '12%', tmedio_atendimento: '12:45s', tmedio_espera: '12:45s', avaliacoes: '12321', nota_media: '5' },
    { servico: 'HEMOGRAFIA', agendamentos: '23423', taxacancelamento: '12%', tmedio_atendimento: '12:45s', tmedio_espera: '12:45s', avaliacoes: '23', nota_media: '5' },
    { servico: 'MAMOGRAFIA', agendamentos: '123432', taxacancelamento: '12%', tmedio_atendimento: '12:45s', tmedio_espera: '12:45s', avaliacoes: '43', nota_media: '5' },
]

const columns = [
    {
        name: "servico",
        label: "SERVIÇOS",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "agendamentos",
        label: "AGENDAMENTOS",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "taxacancelamento",
        label: "TAXA DE CANCELAMENTO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "tmedio_atendimento",
        label: "TEMPO MÉDIO EM ATENDIMENTO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "tmedio_espera",
        label: "TEMPO MÉDIO DE ESPERA",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "avaliacoes",
        label: "AVALIAÇÕES",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "nota_media",
        label: "NOTA MÉDIA",
        options: {
            filter: true,
            sort: false,
        }
    },
];

const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    viewColumns: false,
};

export {
    dataLicensePayments,
    dataRecentServices,
    dataServicesAvailable,
    dataRegisteredProfessionals,
    dataNumbersStatistics,
    columns,
    options,
}