import styled from 'styled-components'

const Icon = styled.img`

`

const Row = styled.div`
  display: flex;
  align-items: center;

  .eye{
    width: 13.91px;
    height: 10px;
    cursor: pointer;
  }
  .check{
    width: 11,65px;
    height: 8,94px;
    margin-left: 5px;
    cursor: pointer;
  }
  .edit{
    width: 11,92px;
    margin-left: 5px;
    height: 12px;
    cursor: pointer;
  }
`

export { Row, Icon }