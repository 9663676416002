import { Avatar as MuiAvatar, Grid } from '@mui/material'
import styled from 'styled-components'
import logo from '../../assets/imgs/medium.png'

const Navbar = styled(Grid)`
  display: flex;
  padding: 0px 1rem;
  align-items: center;
  width: 100vw;
  height: 65px;
  background: white;
  flex-wrap: nowrap !important;

  svg {
    cursor: pointer;
  }

  .orange {
    > span.MuiBadge-badge {
      background-color: #f36b2c;
    }
  }

  @media (min-width: 600px) and (max-width: 1200px) {
    & span.menuName,
    & svg.menuArrow {
      display: none;
    }
  }

  @media (max-width: 600px) {
    ul.menuNavBar {
      column-gap: 0.3rem;
    }
    & span.menuName,
    & svg.menuArrow,
    & svg.profileArrow,
    & span.userName {
      display: none;
    }
    & div.logo {
      width: 33px;
    }
  }
`

const Logo = styled.div`
  background-image: url(${logo});
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: auto;
  padding: 0;
  margin: 0;
  height: 21px;
  width: 115px;
  margin-left: 5px;
  flex: 0 0 auto;
`

const MenuNavBar = styled.ul`
  margin: 0 2rem !important;
  display: flex;
  align-items: center;
  column-gap: 1rem;
`

const MenuItem = styled.li`
  color: var(--ommed);
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  column-gap: 5px;
`

const NavbarProfile = styled(Grid)`
  display: flex;
  align-items: center;
  font-weight: 550;
  gap: 1.5rem;
  margin-left: auto !important;
`

const Tray = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 5px;

  & span.userName {
    white-space: nowrap;
  }
  span{
    cursor: pointer;
  }
`

const Avatar = styled(MuiAvatar)`
  width: 30px !important;
  height: 30px !important;
  border: 2px solid #a2c0d4;
  cursor: pointer;
`

export { Navbar, MenuNavBar, MenuItem, NavbarProfile, Avatar, Tray, Logo }
