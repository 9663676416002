import React from 'react';
import MUIDataTable from "mui-datatables";
import Actions from '../Actions'
import { columns, options } from '../data'

function index({ setScreen, setView }) {
    const data = [
        {
            nome: "Carlos Alburquerque", 
            data_cadastro: '20/12/2021',
            status: "Ativo",
            action: <Actions setScreen={setScreen} setView={setView} /> ,
        },
        {
            nome: "Patricia Ramalho", 
            data_cadastro: '11/01/2022',
            status: "Inativo",
            action: <Actions setScreen={setScreen} setView={setView} /> ,
        },
        {
            nome: "Geraldo Dantas Silva", 
            data_cadastro: '12/01/2022',
            status: "Ativo",
            action: <Actions setScreen={setScreen} setView={setView} /> ,
        },
        {
            nome: "Antônio Freire", 
            data_cadastro: '15/01/2022',
            status: "Inativo",
            action: <Actions setScreen={setScreen} setView={setView} /> ,
        },    
    ];
    
    return <MUIDataTable
        className="table-styles"
        data={data}
        columns={columns}
        options={options}
    />;
}

export default index;
