import React from 'react';
import {
  View,
  EditOrange,
  Confirm
} from '../../../../../assets/imgs/icons'

function Actions({ navigatorTable, setModal }) {
  const viewFunction = () => {
    if (navigatorTable === "Contas a pagar") {
      setModal(prevState => { return { ...prevState, status: true, name: "Fatura" } })
    }
    if (navigatorTable === "Contas a Receber") {
      setModal(prevState => { return { ...prevState, status: true, name: "Fatura" } })
    }
    if (navigatorTable === "Comissões a Pagar") {
      setModal(prevState => { return { ...prevState, status: true, name: "Comissão" } })
    }
  }

  return <div style={{
    display: 'flex',
    alignItems: 'center',
    gap: 5
  }}>
    <img src={View} alt="View" style={{ cursor: 'pointer' }} onClick={viewFunction} />
    <img src={Confirm} style={{ cursor: 'pointer' }} alt="Confirmar" />
    <img src={EditOrange} style={{ cursor: 'pointer' }} alt="Editar" />
  </div>;
}

export default Actions;
