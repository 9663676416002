// source: professionalProfile.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.professionalProfileApi.Address', null, global);
goog.exportSymbol('proto.professionalProfileApi.ApproveProfessionalRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ApproveProfessionalResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.Council', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListApprovedProfessionalsRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListApprovedProfessionalsResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListProfessionalsRequest', null, global);
goog.exportSymbol('proto.professionalProfileApi.ListProfessionalsResponse', null, global);
goog.exportSymbol('proto.professionalProfileApi.Professional', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Address.displayName = 'proto.professionalProfileApi.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Council = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.Council, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Council.displayName = 'proto.professionalProfileApi.Council';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.Professional = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.Professional.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.Professional, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.Professional.displayName = 'proto.professionalProfileApi.Professional';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListProfessionalsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListProfessionalsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListProfessionalsRequest.displayName = 'proto.professionalProfileApi.ListProfessionalsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListProfessionalsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListProfessionalsResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListProfessionalsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListProfessionalsResponse.displayName = 'proto.professionalProfileApi.ListProfessionalsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListApprovedProfessionalsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ListApprovedProfessionalsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListApprovedProfessionalsRequest.displayName = 'proto.professionalProfileApi.ListApprovedProfessionalsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.professionalProfileApi.ListApprovedProfessionalsResponse.repeatedFields_, null);
};
goog.inherits(proto.professionalProfileApi.ListApprovedProfessionalsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ListApprovedProfessionalsResponse.displayName = 'proto.professionalProfileApi.ListApprovedProfessionalsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ApproveProfessionalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ApproveProfessionalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ApproveProfessionalRequest.displayName = 'proto.professionalProfileApi.ApproveProfessionalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.professionalProfileApi.ApproveProfessionalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.professionalProfileApi.ApproveProfessionalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.professionalProfileApi.ApproveProfessionalResponse.displayName = 'proto.professionalProfileApi.ApproveProfessionalResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    street: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    complement: jspb.Message.getFieldWithDefault(msg, 3, ""),
    neighborhood: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, ""),
    postalcode: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Address}
 */
proto.professionalProfileApi.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Address;
  return proto.professionalProfileApi.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Address}
 */
proto.professionalProfileApi.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplement(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNeighborhood(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComplement();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNeighborhood();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostalcode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string street = 1;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string complement = 3;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getComplement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setComplement = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string neighborhood = 4;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getNeighborhood = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setNeighborhood = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string postalCode = 7;
 * @return {string}
 */
proto.professionalProfileApi.Address.prototype.getPostalcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Address} returns this
 */
proto.professionalProfileApi.Address.prototype.setPostalcode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Council.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Council.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Council} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Council.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Council}
 */
proto.professionalProfileApi.Council.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Council;
  return proto.professionalProfileApi.Council.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Council} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Council}
 */
proto.professionalProfileApi.Council.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Council.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Council.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Council} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Council.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.professionalProfileApi.Council.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Council} returns this
 */
proto.professionalProfileApi.Council.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.professionalProfileApi.Council.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Council} returns this
 */
proto.professionalProfileApi.Council.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.professionalProfileApi.Council.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Council} returns this
 */
proto.professionalProfileApi.Council.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.professionalProfileApi.Council.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Council} returns this
 */
proto.professionalProfileApi.Council.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.Professional.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.Professional.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.Professional.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.Professional} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Professional.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    socialsecuritynumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    identitynumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    documentpath: jspb.Message.getFieldWithDefault(msg, 7, ""),
    documentbackpath: jspb.Message.getFieldWithDefault(msg, 8, ""),
    proofofresidencepath: jspb.Message.getFieldWithDefault(msg, 9, ""),
    councildocumentpath: jspb.Message.getFieldWithDefault(msg, 10, ""),
    contractpath: jspb.Message.getFieldWithDefault(msg, 11, ""),
    isapproved: jspb.Message.getFieldWithDefault(msg, 12, 0),
    birthdate: jspb.Message.getFieldWithDefault(msg, 13, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 14, ""),
    registerdate: jspb.Message.getFieldWithDefault(msg, 15, ""),
    councilsList: jspb.Message.toObjectList(msg.getCouncilsList(),
    proto.professionalProfileApi.Council.toObject, includeInstance),
    address: (f = msg.getAddress()) && proto.professionalProfileApi.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.Professional}
 */
proto.professionalProfileApi.Professional.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.Professional;
  return proto.professionalProfileApi.Professional.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.Professional} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.Professional}
 */
proto.professionalProfileApi.Professional.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocialsecuritynumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitynumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentpath(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentbackpath(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProofofresidencepath(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouncildocumentpath(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractpath(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIsapproved(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthdate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegisterdate(value);
      break;
    case 16:
      var value = new proto.professionalProfileApi.Council;
      reader.readMessage(value,proto.professionalProfileApi.Council.deserializeBinaryFromReader);
      msg.addCouncils(value);
      break;
    case 17:
      var value = new proto.professionalProfileApi.Address;
      reader.readMessage(value,proto.professionalProfileApi.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.Professional.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.Professional.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.Professional} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.Professional.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSocialsecuritynumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIdentitynumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDocumentpath();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDocumentbackpath();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProofofresidencepath();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCouncildocumentpath();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getContractpath();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIsapproved();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getBirthdate();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getRegisterdate();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCouncilsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.professionalProfileApi.Council.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.professionalProfileApi.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string firstName = 2;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string socialSecurityNumber = 5;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getSocialsecuritynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setSocialsecuritynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string identityNumber = 6;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getIdentitynumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setIdentitynumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string documentPath = 7;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getDocumentpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setDocumentpath = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string documentBackPath = 8;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getDocumentbackpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setDocumentbackpath = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string proofOfResidencePath = 9;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getProofofresidencepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setProofofresidencepath = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string councilDocumentPath = 10;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getCouncildocumentpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setCouncildocumentpath = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string contractPath = 11;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getContractpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setContractpath = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 isApproved = 12;
 * @return {number}
 */
proto.professionalProfileApi.Professional.prototype.getIsapproved = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setIsapproved = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string birthDate = 13;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getBirthdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setBirthdate = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string phoneNumber = 14;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string registerDate = 15;
 * @return {string}
 */
proto.professionalProfileApi.Professional.prototype.getRegisterdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.setRegisterdate = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated Council councils = 16;
 * @return {!Array<!proto.professionalProfileApi.Council>}
 */
proto.professionalProfileApi.Professional.prototype.getCouncilsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Council>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Council, 16));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Council>} value
 * @return {!proto.professionalProfileApi.Professional} returns this
*/
proto.professionalProfileApi.Professional.prototype.setCouncilsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.professionalProfileApi.Council=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Council}
 */
proto.professionalProfileApi.Professional.prototype.addCouncils = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.professionalProfileApi.Council, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.clearCouncilsList = function() {
  return this.setCouncilsList([]);
};


/**
 * optional Address address = 17;
 * @return {?proto.professionalProfileApi.Address}
 */
proto.professionalProfileApi.Professional.prototype.getAddress = function() {
  return /** @type{?proto.professionalProfileApi.Address} */ (
    jspb.Message.getWrapperField(this, proto.professionalProfileApi.Address, 17));
};


/**
 * @param {?proto.professionalProfileApi.Address|undefined} value
 * @return {!proto.professionalProfileApi.Professional} returns this
*/
proto.professionalProfileApi.Professional.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.professionalProfileApi.Professional} returns this
 */
proto.professionalProfileApi.Professional.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.professionalProfileApi.Professional.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListProfessionalsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListProfessionalsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListProfessionalsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListProfessionalsRequest}
 */
proto.professionalProfileApi.ListProfessionalsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListProfessionalsRequest;
  return proto.professionalProfileApi.ListProfessionalsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListProfessionalsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListProfessionalsRequest}
 */
proto.professionalProfileApi.ListProfessionalsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListProfessionalsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListProfessionalsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListProfessionalsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListProfessionalsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListProfessionalsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListProfessionalsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    professionalsList: jspb.Message.toObjectList(msg.getProfessionalsList(),
    proto.professionalProfileApi.Professional.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse}
 */
proto.professionalProfileApi.ListProfessionalsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListProfessionalsResponse;
  return proto.professionalProfileApi.ListProfessionalsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListProfessionalsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse}
 */
proto.professionalProfileApi.ListProfessionalsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.Professional;
      reader.readMessage(value,proto.professionalProfileApi.Professional.deserializeBinaryFromReader);
      msg.addProfessionals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListProfessionalsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListProfessionalsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListProfessionalsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfessionalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.Professional.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Professional professionals = 4;
 * @return {!Array<!proto.professionalProfileApi.Professional>}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.getProfessionalsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Professional>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Professional, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Professional>} value
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse} returns this
*/
proto.professionalProfileApi.ListProfessionalsResponse.prototype.setProfessionalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.Professional=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Professional}
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.addProfessionals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.Professional, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListProfessionalsResponse} returns this
 */
proto.professionalProfileApi.ListProfessionalsResponse.prototype.clearProfessionalsList = function() {
  return this.setProfessionalsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListApprovedProfessionalsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListApprovedProfessionalsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListApprovedProfessionalsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListApprovedProfessionalsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListApprovedProfessionalsRequest}
 */
proto.professionalProfileApi.ListApprovedProfessionalsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListApprovedProfessionalsRequest;
  return proto.professionalProfileApi.ListApprovedProfessionalsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListApprovedProfessionalsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListApprovedProfessionalsRequest}
 */
proto.professionalProfileApi.ListApprovedProfessionalsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListApprovedProfessionalsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListApprovedProfessionalsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListApprovedProfessionalsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListApprovedProfessionalsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ListApprovedProfessionalsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ListApprovedProfessionalsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    professionalsList: jspb.Message.toObjectList(msg.getProfessionalsList(),
    proto.professionalProfileApi.Professional.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ListApprovedProfessionalsResponse}
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ListApprovedProfessionalsResponse;
  return proto.professionalProfileApi.ListApprovedProfessionalsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ListApprovedProfessionalsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ListApprovedProfessionalsResponse}
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 4:
      var value = new proto.professionalProfileApi.Professional;
      reader.readMessage(value,proto.professionalProfileApi.Professional.deserializeBinaryFromReader);
      msg.addProfessionals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ListApprovedProfessionalsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ListApprovedProfessionalsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfessionalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.professionalProfileApi.Professional.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ListApprovedProfessionalsResponse} returns this
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListApprovedProfessionalsResponse} returns this
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ListApprovedProfessionalsResponse} returns this
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Professional professionals = 4;
 * @return {!Array<!proto.professionalProfileApi.Professional>}
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.prototype.getProfessionalsList = function() {
  return /** @type{!Array<!proto.professionalProfileApi.Professional>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.professionalProfileApi.Professional, 4));
};


/**
 * @param {!Array<!proto.professionalProfileApi.Professional>} value
 * @return {!proto.professionalProfileApi.ListApprovedProfessionalsResponse} returns this
*/
proto.professionalProfileApi.ListApprovedProfessionalsResponse.prototype.setProfessionalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.professionalProfileApi.Professional=} opt_value
 * @param {number=} opt_index
 * @return {!proto.professionalProfileApi.Professional}
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.prototype.addProfessionals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.professionalProfileApi.Professional, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.professionalProfileApi.ListApprovedProfessionalsResponse} returns this
 */
proto.professionalProfileApi.ListApprovedProfessionalsResponse.prototype.clearProfessionalsList = function() {
  return this.setProfessionalsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ApproveProfessionalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ApproveProfessionalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ApproveProfessionalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ApproveProfessionalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    professionalid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ApproveProfessionalRequest}
 */
proto.professionalProfileApi.ApproveProfessionalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ApproveProfessionalRequest;
  return proto.professionalProfileApi.ApproveProfessionalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ApproveProfessionalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ApproveProfessionalRequest}
 */
proto.professionalProfileApi.ApproveProfessionalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfessionalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ApproveProfessionalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ApproveProfessionalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ApproveProfessionalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ApproveProfessionalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfessionalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string professionalId = 1;
 * @return {string}
 */
proto.professionalProfileApi.ApproveProfessionalRequest.prototype.getProfessionalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ApproveProfessionalRequest} returns this
 */
proto.professionalProfileApi.ApproveProfessionalRequest.prototype.setProfessionalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.professionalProfileApi.ApproveProfessionalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.professionalProfileApi.ApproveProfessionalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.professionalProfileApi.ApproveProfessionalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ApproveProfessionalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errormessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.professionalProfileApi.ApproveProfessionalResponse}
 */
proto.professionalProfileApi.ApproveProfessionalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.professionalProfileApi.ApproveProfessionalResponse;
  return proto.professionalProfileApi.ApproveProfessionalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.professionalProfileApi.ApproveProfessionalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.professionalProfileApi.ApproveProfessionalResponse}
 */
proto.professionalProfileApi.ApproveProfessionalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.professionalProfileApi.ApproveProfessionalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.professionalProfileApi.ApproveProfessionalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.professionalProfileApi.ApproveProfessionalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.professionalProfileApi.ApproveProfessionalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.professionalProfileApi.ApproveProfessionalResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.professionalProfileApi.ApproveProfessionalResponse} returns this
 */
proto.professionalProfileApi.ApproveProfessionalResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.professionalProfileApi.ApproveProfessionalResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ApproveProfessionalResponse} returns this
 */
proto.professionalProfileApi.ApproveProfessionalResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string errorMessage = 3;
 * @return {string}
 */
proto.professionalProfileApi.ApproveProfessionalResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.professionalProfileApi.ApproveProfessionalResponse} returns this
 */
proto.professionalProfileApi.ApproveProfessionalResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.professionalProfileApi);
