import React, { useState } from 'react'
import ReactDOM from 'react-dom';
import * as Styled from './Styled'
import { PDFObject } from 'react-pdfobject'
import filePdf from '../../../../assets/documento.pdf'
import FooterPdf from './FooterPdf';
import TableScreen from './TableScreen'
import EditScreen from './EditScreen'

const AllianceMemberScreen = () => {
    const [screen, setScreen] = useState('Table');
    const [view, setView] = useState(false);

    const modalBody = () => (
        <div
            style={{
                backgroundColor: '#fff',
    
                /* Fixed position */
                left: 0,
                position: 'fixed',
                top: 0,
    
                /* Take full size */
                height: '100%',
                width: '100%',
    
                /* Displayed on top of other elements */
                zIndex: 9999,
    
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            <PDFObject url={filePdf} width='100vw' height='calc(100vh - 50px)' />
            <FooterPdf setView={setView} />
        </div>
    );

    return (
        <Styled.Container>
            <Styled.Header>
                <h1>Assinaturas e Contratos</h1>
            </Styled.Header>
            {screen === 'Table' && <TableScreen setScreen={setScreen} setView={setView} />}
            {screen === 'Edit' && <EditScreen />}
            {
                view && <>{ReactDOM.createPortal(modalBody(), document.body)}</>
            }
        </Styled.Container>
    )
}

export default AllianceMemberScreen
