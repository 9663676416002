import styled from 'styled-components'


const Message = styled.p`
    font-family: SFProText;
    color: #828282;
    font-size: 14px;
`

const Input = styled.input`
    width: 96%;
    border: 2px solid #F2F2F2;
    border-radius: 10px;
    padding: 15px;
    margin-top: 15px;
    transition:all 0.3s ease;
`

const Modal = styled.div`
    border-radius: 15px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    color: #fff;
    width: 80%;
    padding: 15px;
    background: #2D313D;
    font-size: 14px;
    h1{
        font-size: 16px;
    }
    p{
        width: 80%;
        margin: 0 auto;
    }
`
const Button = styled.button`
    width: 60%;    
    margin: 0 auto;
    padding: 15px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition:all 0.5s ease;
    margin-top: 8px;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #ff6228;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        font-style: italic;
    }

`

export { Message, Input, Modal, Button }
