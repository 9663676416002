import React from 'react';
import * as Styled from './Styled'
import {
    Close,
    Share,
    Print
} from '../../../../../assets/imgs/icons'

function Modals({ modal, data, setModal }) {
    // const { id } = modal
    // const [dados, setDados] = useState('')

    return <>
        <Styled.Container>
            {modal.name === "Fatura" && <div>
                <Styled.Header>
                    <Styled.Column>
                        <h1>Fatura</h1>
                        <h2>#123431235</h2>
                    </Styled.Column>
                    <Styled.Row style={{justifyContent: 'flex-end', alignItems: 'center', gap: 10}}>
                        <img src={Print} alt="Print" />
                        <img src={Share} alt="Share" />
                        <img src={Close} alt="Close" onClick={() => setModal({
                            status: false,
                            id: undefined,
                            name: ''
                        })} />
                    </Styled.Row>
                </Styled.Header>
                <Styled.Row className='mt-10'>
                    <b>Favorecido</b>
                    <p className='favorecido'>Casa do Material de Construção</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Natureza do parceiro</b>
                    <p>Fornecedor</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Forma de Pagamento</b>
                    <p>Boleto</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Vencimento</b>
                    <p>05/10/2021</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Período</b>
                    <p>Mensal</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Valor</b>
                    <p>R$ 234,00</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Número de contrato vinculado</b>
                    <p>#12325553</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Membro da Aliança referente</b>
                    <p>Clínica Almir Santos Neto</p>
                </Styled.Row>
                <hr />
                <Styled.Column>
                    <b>Descrição</b>
                    <p className='mt-5'>Pagamento referente à comissão do contrato #12325553 realizado no dia 12/08/2021.</p>
                </Styled.Column>

                <Styled.Right className='mt-10'>
                    <Styled.Button className='blue'>Pago</Styled.Button>
                    <Styled.Button>Cancelar registro</Styled.Button>
                    <Styled.Button className='cancel'>Sair</Styled.Button>
                </Styled.Right>
            </div>}
            {modal.name === "Comissão" && <div>
                <Styled.Header>
                    <Styled.Column>
                        <h1>Comissão</h1>
                        <h2>#123431235</h2>
                    </Styled.Column>
                    <Styled.Row style={{justifyContent: 'flex-end', alignItems: 'center', gap: 10}}>
                        <img src={Print} alt="Print" />
                        <img src={Share} alt="Share" />
                        <img src={Close} alt="Close" onClick={() => setModal({
                            status: false,
                            id: undefined,
                            name: ''
                        })} />
                    </Styled.Row>
                </Styled.Header>
                <Styled.Row className='mt-10'>
                    <b>Colaborador</b>
                    <p className='favorecido'>Paulo Amorim</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Natureza do parceiro</b>
                    <p>Comercial</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Forma de Pagamento</b>
                    <p>PIX</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Vencimento</b>
                    <p>05/10/2021</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Período</b>
                    <p>Mensal</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Valor</b>
                    <p>R$ 234,00</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Número de contrato vinculado</b>
                    <p>#12325553</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Membro da Aliança referente</b>
                    <p>Clínica Almir Santos Neto</p>
                </Styled.Row>
                <hr />
                <Styled.Column>
                    <b>Descrição</b>
                    <p className='mt-5'>Pagamento referente à comissão do contrato #12325553 realizado no dia 12/08/2021.</p>
                </Styled.Column>

                <Styled.Right className='mt-10'>
                    <Styled.Button className='blue'>Programar</Styled.Button>
                    <Styled.Button>Cancelar registro</Styled.Button>
                    <Styled.Button className='cancel'>Sair</Styled.Button>
                </Styled.Right>
            </div>}
        </Styled.Container>
    </>;
}

export default Modals;
