import React from 'react'
import * as Styled from './Styled'
import BackIcon from '../../../../assets/imgs/icons/back.png'
import TableScreen from './TableScreen'

const PlatformsLogs = () => {

    return (
        <Styled.Container>
            <Styled.Header>
                <Styled.Row>
                    <img src={BackIcon} alt="Back" />
                    <h1>Plataformas / <span>Logs</span></h1>
                </Styled.Row>
            </Styled.Header>
            <TableScreen />
        </Styled.Container>
    )
}

export default PlatformsLogs
