import styled from 'styled-components'
import Grid from '@mui/material/Grid';

const Container = styled(Grid)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 98%;
  margin: 0 auto;
  .table-styles{
    margin-top: 10px;
    width: 100%;
    background: #fafafa;
  }
  tr, th{
    background: #fafafa !important;
  }
  th{
    color: #BDBDBD;
  }
  td{
    color: var(--ommed);
  }
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  h1{
    font-family: "SFProDisplay-Bold";
    color: var(--ommed);
    margin: 1rem;
    span{color: #EB5757;}
  }
`

const Button = styled.button`
    width: 180px;    
    height: 40px;
    border: none;
    border-radius: 10px;
    cursor: pointer;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: var(--gradiente-main-wk);  /* Chrome 10-25, Safari 5.1-6 */
    background: var(--gradiente-main); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #ff6228;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        font-style: italic;
    }
`

export { Container, Header, Button }