import React, { useState } from 'react';
import * as Styled from './Styled'
import Grid from '@mui/material/Grid';
import { UpdateInfoRequest } from '../../../../../core/grpc/generated/adminAccount_pb'
import { updateInfoService } from '../../../../../core/services/adminService'

function EditScreen() {
  const [dados, setDados] = useState({
    nome: '',
    sobre_nome: '',
    email: '',
    telefone: '',
    senha: '',
    confirm: '',
  })

  const update = async () => {
    var request = new UpdateInfoRequest();
    request.setFirstname(dados.nome)
    request.setLastname(dados.sobre_nome)
    request.setEmail(dados.email)
    request.setPhone(dados.telefone)
    request.setPassword(dados.senha)
    request.setConfirm(dados.confirm)
    const response = await updateInfoService(request)

    console.log(response)
    // if (response.success === true) {

    // }
  }

  return <Styled.Container container>
    <Grid item lg={12} xs={12} className='item'>
      <Styled.Title>Dados Básicos</Styled.Title>
      <Styled.Input placeholder='Nome' type="text"
        onChange={({ target }) => setDados({ ...dados, nome: target.value })} />

      <Styled.Input placeholder='Sobrenome' type="text"
        onChange={({ target }) => setDados({ ...dados, sobre_nome: target.value })} />

      <Styled.Input placeholder='Email' type="text"
        onChange={({ target }) => setDados({ ...dados, email: target.value })} />

      <Styled.Input placeholder='Telefone: +55 DDD NUMERO' type="text"
        onChange={({ target }) => setDados({ ...dados, telefone: target.value })} />

      <Styled.Input placeholder='Senha' type="text"
        onChange={({ target }) => setDados({ ...dados, senha: target.value })} />

      <Styled.Input placeholder='Confirmação senha' type="text"
        onChange={({ target }) => setDados({ ...dados, confirm: target.value })} />
    </Grid>
    {/* <Grid container item lg={6} xs={12} className='item'>
      <Styled.Title>Endereço</Styled.Title>
      <Styled.Input placeholder='Endereço' type="text" />
      <Styled.Input placeholder='Número' type="text" />
      <Styled.Input placeholder='Bairro' type="text" />
      <Styled.Input placeholder='Complemento' type="text" />
      <Styled.Input placeholder='Estado' type="text" />
      <Styled.Input placeholder='Cidade' type="text" />
      <Styled.Input placeholder='CEP' type="text" />
    </Grid> */}
    {/* <Grid item lg={4} xs={12} className='item'>
      <Styled.Title>Plano</Styled.Title>
      <Styled.Input placeholder='Plano' type="text" />
      <Styled.Input placeholder='Periodicidade' type="text" />
      <Styled.Input placeholder='Calor do plano' type="text" />
      <Styled.Input placeholder='Data de Expiração' type="text" />
      <Styled.Input placeholder='Forma de Pagamento' type="text" />
      <Styled.Input placeholder='Status de atividade' type="text" />
    </Grid>
    <Grid item lg={4} xs={12} className='item'>
      <Styled.Title>Dados de Responsável</Styled.Title>
      <Styled.Input placeholder='Nome do Responsável' type="text" />
      <Styled.Input placeholder='Telefone (Whatsapp)' type="text" />
      <Styled.Input placeholder='Email' type="text" />
      <Styled.Input placeholder='CPF' type="text" />
      <Styled.Input placeholder='Cargo na empresa' type="text" />
      <Styled.Input placeholder='Data de Nascimento' type="text" />
    </Grid>
    <Grid container item lg={8} xs={12} direction="row" className='item'>
      <Grid item lg={6} xs={12} className='item'>
        <Styled.Title>Dados tecnicos</Styled.Title>
        <Styled.Input placeholder='Token Privado' type="text" />
        <Styled.Input placeholder='Token Público' type="text" />
        <Styled.Input placeholder='Senha de acesso' type="text" />
        <Styled.Input placeholder='Data de cadastro' type="text" />
      </Grid>
      <Grid item lg={6} xs={12} className='item'>
        <Styled.Title>redes sociais</Styled.Title>
        <Styled.Input placeholder='Instagram' type="text" />
        <Styled.Input placeholder='Facebook' type="text" />
        <Styled.Input placeholder='Youtube' type="text" />
        <Styled.Input placeholder='Twitter' type="text" />
      </Grid>
      <Grid item lg={12} xs={12}>
        <Styled.TextArea placeholder='Apresentação' rows="4" />
      </Grid>
    </Grid> */}
    <Styled.Button onClick={update}>Editar</Styled.Button>
  </Styled.Container>;
}

export default EditScreen;
