import React, { useState } from 'react';
import MUIDataTable from "mui-datatables"
import * as Styled from './Styled'
import Actions from './Actions'
import { columns, options } from './data'
import { Close } from '../../../../assets/imgs/icons'

function Providers() {
  const [modal, setModal] = useState({
    status: false,
    id: null,
    name: '',
  })

  const data = [
    {
      nome: "CASA DO MATÉRIAL DE CONSTRUÇÃO",
      cod_contrato: "45636563",
      tel_contato: "(11) 4949-9872",
      responsavel: "Gabriel Assis",
      cpf_cnpj: "033.323.435-90",
      natureza: "Mensal",
      valor: "R$ 234,00",
      actions: <Actions setModal={setModal} />
    },
    {
      nome: "FARMACO AGRO2",
      cod_contrato: "3453455",
      tel_contato: "(11) 4949-9872",
      responsavel: "Vivian Torres",
      cpf_cnpj: "033.323.435-90",
      natureza: "Mensal",
      valor: "R$ 234,00",
      actions: <Actions setModal={setModal} />
    },
    {
      nome: "JOÃO ASSIS",
      cod_contrato: "6543656",
      tel_contato: "(11) 4949-9872",
      responsavel: "João Assis",
      cpf_cnpj: "033.323.435-90",
      natureza: "Serviço",
      valor: "---",
      actions: <Actions setModal={setModal} />
    },
    {
      nome: "CASA DO MATÉRIAL DE CONSTRUÇÃO",
      cod_contrato: "45636563",
      tel_contato: "(11) 4949-9872",
      responsavel: "Gabriel Assis",
      cpf_cnpj: "033.323.435-90",
      natureza: "Mensal",
      valor: "R$ 234,00",
      actions: <Actions setModal={setModal} />
    },
    {
      nome: "FARMACO AGRO2",
      cod_contrato: "3453455",
      tel_contato: "(11) 4949-9872",
      responsavel: "Vivian Torres",
      cpf_cnpj: "033.323.435-90",
      natureza: "Mensal",
      valor: "R$ 234,00",
      actions: <Actions setModal={setModal} />
    },
    {
      nome: "JOÃO ASSIS",
      cod_contrato: "6543656",
      tel_contato: "(11) 4949-9872",
      responsavel: "João Assis",
      cpf_cnpj: "033.323.435-90",
      natureza: "Serviço",
      valor: "---",
      actions: <Actions setModal={setModal} />
    },
  ]

  return (<Styled.Container>
    <Styled.Row>
      <h1>FORNECEDORES</h1>
      <button onClick={() => setModal({ status: true, id: null, name: 'New' })}>ADICIONAR FORNECEDORES</button>
    </Styled.Row>
    <MUIDataTable
      className="table-styles"
      data={data}
      columns={columns}
      options={options}
    />
    {modal.status && <Styled.Modal>
      {modal.name === "New" && <>
        <Styled.Row>
          <h1>ADICIONAR FORNECEDORES</h1>
          <img src={Close} alt="Close" onClick={() => setModal({ status: false, id: null, name: '' })} />
        </Styled.Row>
        <Styled.Row className='mt-10'>
          <p>Nome/Razão Social</p>
          <input type="text" placeholder='Nome/Razão Social' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>CNPJ/CPF</p>
          <input type="text" placeholder='CNPJ/CPF' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Email</p>
          <input type="text" placeholder='Email' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Telefone</p>
          <input type="text" placeholder='Telefone' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Responsável</p>
          <input type="text" placeholder='Nome do responsável' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>CPF Responsável</p>
          <input type="text" placeholder='CPF' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Endereço</p>
          <input type="text" placeholder='Endereço' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Estado</p>
          <input type="text" placeholder='Estado' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Cidade</p>
          <input type="text" placeholder='Cidade' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>CEP</p>
          <input type="text" placeholder='CEP' />
        </Styled.Row>
        <hr />
        <Styled.Column>
          <p>Descrição</p>
          <textarea className='mt-10' placeholder='Descreva as atividades deste fornecedor' rows={4} />
        </Styled.Column>

        <Styled.Right className='mt-10'>
          <Styled.Button>Cadastrar</Styled.Button>
          <Styled.Button className='cancel'>Sair</Styled.Button>
        </Styled.Right>
      </>}
      {modal.name === "Edit" && <>
        <Styled.Row>
          <h1>CASA DO MATERIAL DE CONSTRUÇÃO</h1>
          <img src={Close} alt="Close" onClick={() => setModal({ status: false, id: null, name: '' })} />
        </Styled.Row>
        <Styled.Row className='mt-10'>
          <p>Nome/Razão Social</p>
          <input type="text" defaultValue={"Casa do Material de Construção"} placeholder='Nome/Razão Social' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>CNPJ/CPF</p>
          <input type="text" defaultValue="033.323.435-90" placeholder='CNPJ/CPF' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Email</p>
          <input type="text" defaultValue="casaomaterial@gmail.com" placeholder='Email' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Telefone</p>
          <input type="text" defaultValue="(21) 9 8654-8787" placeholder='Telefone' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Responsável</p>
          <input type="text" defaultValue="Gabriel Assis" placeholder='Nome do responsável' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>CPF Responsável</p>
          <input type="text" defaultValue="033.323.435-90" placeholder='CPF' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Endereço</p>
          <input type="text" defaultValue="Rua dos Amores, 342" placeholder='Endereço' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Estado</p>
          <input type="text" defaultValue="Rio de Janeiro" placeholder='Estado' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Cidade</p>
          <input type="text" defaultValue="Rio de Janeiro" placeholder='Cidade' />
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>CEP</p>
          <input type="text" defaultValue="32.231-098" placeholder='CEP' />
        </Styled.Row>
        <hr />
        <Styled.Column>
          <p>Descrição</p>
          <textarea className='mt-10' defaultValue="Fornecedor de material de construção para a reforma da nova sede da OMMED." placeholder='Descreva as atividades deste fornecedor' rows={4} />
        </Styled.Column>

        <Styled.Right className='mt-10'>
          <Styled.Button>Alterar</Styled.Button>
          <Styled.Button className='cancel'>Sair</Styled.Button>
        </Styled.Right>
      </>}
      {modal.name === "View" && <>
        <Styled.Row>
          <h1>CASA DO MATERIAL DE CONSTRUÇÃO</h1>
          <img src={Close} alt="Close" onClick={() => setModal({ status: false, id: null, name: '' })} />
        </Styled.Row>
        <Styled.Row className='mt-10'>
          <p style={{ maxWidth: '30%' }}>Nome/Razão Social</p>
          <p>Casa do Material de construção</p>
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>CNPJ/CPF</p>
          <p>033.323.435-90</p>
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Email</p>
          <p>casaomaterial@gmail.com</p>
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Telefone</p>
          <p>(21) 9 8654-8787</p>
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Responsável</p>
          <p>Gabriel Assis</p>
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>CPF Responsável</p>
          <p>033.323.435-90</p>
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Endereço</p>
          <p>Rua dos Amores, 342</p>
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Estado</p>
          <p>Rio de Janeiro</p>
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>Cidade</p>
          <p>Rio de Janeiro</p>
        </Styled.Row>
        <hr />
        <Styled.Row>
          <p>CEP</p>
          <p>87.231-098</p>
        </Styled.Row>
        <hr />
        <Styled.Column>
          <p>Descrição</p>
          <Styled.TextAreaDiv>
            Fornecedor de material de construção para a reforma da nova sede da OMMED.
          </Styled.TextAreaDiv>
        </Styled.Column>

        <Styled.Right className='mt-10'>
          <Styled.Button className='blue'>Ordens de serviço</Styled.Button>
          <Styled.Button className='blue'>Histórico</Styled.Button>
          <Styled.Button>Alterar dados</Styled.Button>
        </Styled.Right>
      </>}
    </Styled.Modal>}
  </Styled.Container>)
}

export default Providers;
