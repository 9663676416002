import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";

function TableScreen() {
    const [data, setData] = useState([
        {
            codigo: "#5645645-334-WJS",
            modulo: "Login",
            data_log: "2022-02-05",
            log_message: `info: Microsoft.AspNetCore.Hosting.Diagnostics[1]\n
                Request starting HTTP/ 2 GET https://localhost:5001/Privacy\n
            info: Microsoft.AspNetCore.Routing.EndpointMiddleware[0]\n
                Executing endpoint '/Privacy'\n
            info: Microsoft.AspNetCore.Mvc.RazorPages.Infrastructure.PageActionInvoker[3]\n
                Route matched with { page = "/Privacy" }.Executing page / Privacy\n
            info: Microsoft.AspNetCore.Mvc.RazorPages.Infrastructure.PageActionInvoker[101]\n
                Executing handler method DefaultRP.Pages.PrivacyModel.OnGet - ModelState is Valid\n
            info: Microsoft.AspNetCore.Mvc.RazorPages.Infrastructure.PageActionInvoker[102]\n
                Executed handler method OnGet, returned result.\n
            info: Microsoft.AspNetCore.Mvc.RazorPages.Infrastructure.PageActionInvoker[103]\n
                Executing an implicit handler method - ModelState is Valid\n
            info: Microsoft.AspNetCore.Mvc.RazorPages.Infrastructure.PageActionInvoker[104]\n
                Executed an implicit handler method, returned result Microsoft.AspNetCore.Mvc.RazorPages.PageResult.\n
            info: Microsoft.AspNetCore.Mvc.RazorPages.Infrastructure.PageActionInvoker[4]\n
                Executed page / Privacy in 74.5188ms\n
            info: Microsoft.AspNetCore.Routing.EndpointMiddleware[1]\n
                Executed endpoint '/Privacy'\n
            info: Microsoft.AspNetCore.Hosting.Diagnostics[2]\n
                Request finished in 149.3023ms 200 text / html; charset = utf - 8\n`
        },
        {
            codigo: "#5645645-331-WJS",
            modulo: "Cadastro",
            data_log: "2022-02-07",
            log_message: "Teste 2"
        },
    ])

    const columns = [
        {
            name: "modulo",
            label: "MÓDULO",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <b>{value}</b>
                    );
                }
            }
        },
        {
            name: "data_log",
            label: "DATA DO LOG",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, { rowIndex }) => {
                    function onChangeDate(e) {
                        let arr = data
                        arr[rowIndex].data_log = e.target.value
                        arr = [...arr]
                        setData(arr)
                    }

                    return <input style={{
                        textAlign: 'center',
                        border: '1px solid #DDDFE1'
                    }} type="date" value={value} onChange={onChangeDate} />
                }
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        viewColumns: false,
        expandableRows: true,
        renderExpandableRow: (rowData, { rowIndex }) => {
            function Log({text}) {
                const newLine = text.split('\n').map(str => <p>{str}</p>);

                return newLine;
            }

            return (
                <tr>
                    <td colSpan={'3'} style={{
                        background: '#F2F2F2',
                        borderRadius: 8,
                        padding: 10,
                        width: '100%'
                    }}>
                        <Log text={data[rowIndex].log_message} />
                    </td>
                </tr>
            );
        },
    };

    return <MUIDataTable
        title={"LOGS"}
        className="table-styles"
        data={data}
        columns={columns}
        options={options}
    />;
}

export default TableScreen;
