import React, { useState } from 'react'
import * as Styled from './Styled'
import Actions from './Actions'
import TableScreen from './TableScreen'
import Modals from './Modals';
import {
    Back
} from '../../../../assets/imgs/icons'

const PlatformsSettings = () => {
    const [modal, setModal] = useState({
        status: false,
        id: null,
        name: null,
    })
    const [data, setData] = useState([
        {
            chave: "#5645645-334-WJS",
            assunto: "Alerta de consulta agenda para o dia",
            assunto_str: "Alerta de consulta agenda para o dia",
            plataforma: "Pacientes",
            tipo_componente: "Alerta",
            data_cadastro: "05/10/2021",
            status: true,
            action: <Actions setModal={setModal} idX={0} />,
        },
        {
            chave: "#5645645-331-WJS",
            assunto: "Paciente remarcou consulta",
            assunto_str: "Paciente remarcou consulta",
            plataforma: "Profisisonais",
            tipo_componente: "Alerta",
            data_cadastro: "05/10/2021",
            status: true,
            action: <Actions setModal={setModal} idX={1} />,
        },
        {
            chave: "#5645645-332-WJS",
            assunto: "Você tem uma nova mensagem no chat",
            assunto_str: "Você tem uma nova mensagem no chat",
            plataforma: "Profisisonais e Pacientes",
            tipo_componente: "Mensagem",
            data_cadastro: "05/10/2021",
            status: false,
            action: <Actions setModal={setModal} idX={2} />,
        },
        {
            chave: "#5645645-337-WJS",
            assunto: "Você possui um Bônus",
            assunto_str: "Você possui um Bônus",
            plataforma: "Pacientes",
            tipo_componente: "Alerta",
            data_cadastro: "05/10/2021",
            status: true,
            action: <Actions setModal={setModal} idX={3} />,
        },
        {
            chave: "#5645645-335-WJS",
            assunto: "Você possui nova consulta agendada",
            assunto_str: "Você possui nova consulta agendada",
            plataforma: "Profisisonais",
            tipo_componente: "Alerta",
            data_cadastro: "05/10/2021",
            status: true,
            action: <Actions setModal={setModal} idX={4} />,
        },
        {
            chave: "#5645645-338-WJS",
            assunto: "Precisa concluir seu cadastro",
            plataforma: "Profisisonais e Pacientes",
            tipo_componente: "Alerta",
            data_cadastro: "05/10/2021",
            status: true,
            action: <Actions setModal={setModal} idX={5} />,
        },
    ])


    const ModalBody = () => (
        <Styled.Modal>
            <Modals modal={modal} data={data} setData={setData} setModal={setModal} />
        </Styled.Modal>
    );

    return (
        <Styled.Container>
            <Styled.Header>
                <Styled.Row>
                    <img src={Back} alt="Back" />
                    <h1>Plataformas / <span>Configurações</span></h1>
                </Styled.Row>
                <Styled.Button onClick={() => setModal({
                    status: true,
                    id: undefined,
                    name: 'New'
                })}>NOVO REGISTRO</Styled.Button>
            </Styled.Header>
            <TableScreen data={data} />
            {
                modal.status && <ModalBody />
            }
        </Styled.Container>
    )
}

export default PlatformsSettings
