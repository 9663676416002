import React, { useState, useContext, useEffect } from 'react'
import * as Styled from './Styled'
import { GeneralContext } from '../../../GeneralContext'
import Esqueceu from '../../../assets/imgs/esqueceu.png'
import { LoginRequest } from '../../../core/grpc/generated/adminAccount_pb'
import { loginService } from '../../../core/services/adminService'
import { useNavigate } from 'react-router-dom';

const CardLogin = ({ setScreen, dados, setDados }) => {
    const history = useNavigate();
    const token = window.localStorage.getItem("token");
    const { setLogged } = useContext(GeneralContext);
    const [loginInvalid, setLoginInvalid] = useState(false);
    const [submitLogin, setSubmitLogin] = useState(false);

    const inputData = (e) => {
        setDados(prevState => { return { ...prevState, [e.target.name]: e.target.value } })
    }

    const forgotMyPassword = () => {
        setDados(prevState => { return { ...prevState, password: '' } })
        setScreen('ForgotPassword')
    }

    const submit = async () => {
        const FILLED_LOGIN_PASS = dados.user !== '' && dados.password !== ''
        setSubmitLogin(true)
        if (FILLED_LOGIN_PASS) {
            var request = new LoginRequest();
            request.setUser(dados.user)
            request.setPassword(dados.password)
            const response = await loginService(request)

            console.log(response)
            if (response.success) {
                const { token, email, phone } = JSON.parse(response.data)
                localStorage.setItem('token', token)
                localStorage.setItem('email', email)
                localStorage.setItem('phone', phone)
                setLogged(true)
                history('/admin/membro-alianca/contratos')
            } else {
                if (response.errormessage === 'Usuário/Senha inválido(s)') {
                    setLoginInvalid(true)
                }
            }
        }
    }

    useEffect(() => {
        if (token) {
            setLogged(true)
            history('/admin/membro-alianca/contratos')
        }
    }, [history, setLogged, token]);

    return (
        <>
            {
                loginInvalid && <Styled.ErrorMessage>
                    Email ou senha digitados são inválidos. Tente novamente!
                </Styled.ErrorMessage>
            }

            <Styled.Input name="user" defaultValue={dados.user} placeholder='Email' type="email" className='email' onChange={inputData} required />

            {
                (submitLogin && dados.user === '') &&
                <Styled.ErrorMessage>
                    Esse campo é obrigatório
                </Styled.ErrorMessage>
            }

            <Styled.Input name="password" placeholder='Senha' type="password" onChange={inputData} className='senha' />
            {
                (submitLogin && dados.password === '') &&
                <Styled.ErrorMessage className='second'>
                    Esse campo é obrigatório
                </Styled.ErrorMessage>
            }
            <Styled.Button onClick={submit}>
                Entrar
            </Styled.Button>

            <Styled.Esqueci onClick={forgotMyPassword}>
                <img src={Esqueceu} alt="Esqueceu Senha" className='mr-5' />
                Esqueci minha senha
            </Styled.Esqueci>
        </>
    )
}

export default CardLogin
