import React from 'react'
import { useNavigate } from 'react-router-dom';
import imgProfile from '../../assets/imgs/profile.jpg'
import * as Styled from './Styled'
import { menu } from './data'
import {
  ArrowDropDown as ArrowDropDownIcon,
  MessageOutlined as MessageIcon,
  NotificationsOutlined as NotificationsIcon,
  FilterList as FilterListIcon
} from '@mui/icons-material'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Badge } from '@mui/material'

function Navbar() {
  const history = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = e => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const logout = () => {
    handleClose()
    localStorage.removeItem("token")
    localStorage.removeItem("phone")
    localStorage.removeItem("email")
    localStorage.removeItem("date")
    history('/login')
  }

  return (
    <Styled.Navbar container>
      <FilterListIcon />
      <Styled.Logo className='logo' />
      <Styled.MenuNavBar item className='menuNavBar'>
        {menu.map(({ name }) => {
          return (
            <Styled.MenuItem key={name}>
              <span className='menuName'>{name}</span>
              <ArrowDropDownIcon className='menuArrow' />
            </Styled.MenuItem>
          )
        })}
      </Styled.MenuNavBar>

      <Styled.NavbarProfile item>
        <Styled.Tray>
          <Badge variant='dot' overlap='circular' className='orange'>
            <MessageIcon />
          </Badge>
          <Badge variant='dot' overlap='circular' className='orange'>
            <NotificationsIcon />
          </Badge>
        </Styled.Tray>
        <Styled.Tray>
          <Styled.Avatar src={imgProfile} alt='profile' onClick={handleClick} />
          <span className='userName' onClick={handleClick}>Talles Carrelo</span>
          <ArrowDropDownIcon className='profileArrow' onClick={handleClick} />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </Styled.Tray>
      </Styled.NavbarProfile>
    </Styled.Navbar>
  )
}

export default Navbar
