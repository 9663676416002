const columnsAPagar = [
    {
        name: "favorecido",
        label: "FAVORECIDO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "natureza_favorecido",
        label: "NATUREZA DO FAVORECIDO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "forma_pagamento",
        label: "FORMA DE PAGAMENTO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "n_documento",
        label: "NÚMERO DO DOCUMENTO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "vencimento",
        label: "VENCIMENTO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "parcela",
        label: "PARCELA",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "valor",
        label: "VALOR",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "actions",
        label: " ",
        options: {
            filter: true,
            sort: false,
        }
    },
];

const columnsAReceber = [
    {
        name: "membro_alianca",
        label: "MEMBRO DA ALIANÇA",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "natureza_membro",
        label: "NATUREZA DO MEMBRO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "forma_pagamento",
        label: "FORMA DE PAGAMENTO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "n_documento",
        label: "NÚMERO DO DOCUMENTO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "vencimento",
        label: "VENCIMENTO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "licenca",
        label: "LICENÇA",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "valor",
        label: "VALOR",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "actions",
        label: " ",
        options: {
            filter: true,
            sort: false,
        }
    },
];

const columnsComissoesAPagar = [
    {
        name: "colaborador",
        label: "COLABORADOR",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "natureza_parceiro",
        label: "NATUREZA DO PARCEIRO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "forma_pagamento",
        label: "FORMA DE PAGAMENTO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "n_proposta",
        label: "NÚMERO DA PROPOSTA",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "vencimento",
        label: "VENCIMENTO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "periodicidade",
        label: "PERIODICIDADE",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "valor",
        label: "VALOR",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "actions",
        label: " ",
        options: {
            filter: true,
            sort: false,
        }
    },
];

const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    viewColumns: false,
};

export {
    columnsAPagar,
    columnsAReceber,
    columnsComissoesAPagar,
    options,
}