import styled from 'styled-components'
import Grid from '@mui/material/Grid';

const Container = styled(Grid)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 98%;
  margin: 0 auto;
  .table-styles{
    margin-top: 10px;
    width: 100%;
    background: #fafafa;
  }
  tr, th{
    background: #fafafa !important;
  }
  th{
    color: #464545;
    font-weight: bold;
  }
  td{
    color: var(--ommed);
    b{
      color: #5D5C5C;
    }
  }
  h6{ font-size: 14px; font-weight: bold;}
`

const ContainerCard = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 10px;
  padding: 0;
  margin-top: 15px;
  @media(max-width: 1199px){
    flex-wrap: wrap;
  }
`

const Card = styled(Grid)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  
  max-width: calc(100% / 2 - 5px) !important;
  color: var(--ommed);
  border: 1px solid #E0E0E0;
  border-radius: 10px;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  align-items: center;
  .green{background: #27AE60;}
  .orange{background: #F2994A;}
  .greeninative{background: #6FCF97;}
  .red{background: #EB5757;}
  .darklighter{background: #A2C0D4;}
  h1{font-size: 1.4rem;margin: 0 !important;}
  .status{font-size: 12px; padding-top: 15px;}
  .ativo{color: #27AE60;}
  img{cursor: pointer;}
  @media(max-width: 1042px){
    button{
      width: 100%;
    }
  }
`

const BoxTable = styled(Grid)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;

  h1{
    font-size: 14px;
  }
  hr{border-color: #E0E0E0 !important; width: 100%; opacity: .2;}

`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  h1{
    font-family: "SFProDisplay-Bold";
    color: #2D313D;
    margin: 1rem;
    span{color: #EB5757;}
  }
`

const Button = styled.button`
    height: 30px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    padding: 0px 15px;

    color: #fff;
    font-size: 12px;
    font-weight: 500;
    background: #f64300;  /* fallback for old browsers */
    background: var(--gradiente-main-wk);  /* Chrome 10-25, Safari 5.1-6 */
    background: var(--gradiente-main); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #ff6228;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
`

const BoxBtnTable = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 98%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: -9px;
  .btnNew{margin-bottom: 10px; font-weight: bold;}
  .red{background: #EB5757; color: #FAFAFA;}
  .green{background: #27AE60; color: #FAFAFA;}
  .row{
    display: flex;
    button{
      font-size: 14px;
      font-weight: bold;
      display:flex;
      justify-content: center;
      align-items: center;
      padding: 20px 25px;
      margin-right: 10px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .active{
      background: #828282;
      color: #fafafa;
      b{
        color: #e0e0e0;
        margin-left: 8px;
      }
    }
    .disable{
      background: #ffffff;
      color: var(--ommed);
      b{
        color: var(--bg-darklighter);
        margin-left: 8px;
      }
    }
  }
`

const Modal = styled.div`
  background-color: #FAFAFA;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  /* Fixed position */
  left: calc(50% - 175px);
  right: calc(50% - 175px);
  position: fixed;
  top: 100px;
  padding: 20px;
  border-radius: 10px;

  /* Take full size */
  width: 350px;

  /* Displayed on top of other elements */
  z-index: 9999;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export { 
  Container, 
  ContainerCard, 
  Card, 
  BoxTable, 
  Row, 
  Header, 
  Button, 
  BoxBtnTable,
  Modal
}