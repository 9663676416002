import React from 'react';
import * as Styled from './Styled'
import MUIDataTable from "mui-datatables";
import {
    dataLicensePayments,
    dataRecentServices,
    dataServicesAvailable,
    dataRegisteredProfessionals,
    dataNumbersStatistics,
    columns,
    options
} from './data'
import {
    Back,
    QrCode,
    EditOrange
} from '../../../../../assets/imgs/icons'

const ViewScreen = ({ setView }) => {
    return <Styled.Container container>
        <Styled.Header className='gap'>
            <Styled.Column item xs={12} lg={6} style={{ alignItems: 'flex-start' }}>
                <Styled.ID>#34134</Styled.ID>
                <Styled.Row><img src={Back} alt="back" onClick={() => setView(false)} /><h1>Clínica Carlos Batista</h1><p className='status ativo'>[ATIVO]</p></Styled.Row>
                <p>Membro da aliança desde 10 de julho de 2021</p>
            </Styled.Column>
            <Styled.Column container item xs={12} lg={6} style={{ alignItems: 'flex-end' }}>
                <p className='tr'>João Pessoa - Paraíba [ <span className='red'>(83) 3043-4554</span> - <span className='blue'>(83) 98743-4554</span> ]</p>
                <Styled.Row>
                    <Styled.Button className='green'>Ligar agora</Styled.Button>
                    <Styled.Button className='orange'>Dados Cadastrais</Styled.Button>
                    <Styled.Button className='greeninativo'>Serviços</Styled.Button>
                    <Styled.Button className='red'>Suporte</Styled.Button>
                    <Styled.Button className='darklighter'>Anexos</Styled.Button>
                </Styled.Row>
            </Styled.Column>
        </Styled.Header>
        <Styled.ContainerCard container>
            <Styled.Card item xs={6} lg={2}>
                <b>SERVIÇOS</b>
                <p>32</p>
            </Styled.Card>
            <Styled.Card item xs={6} lg={2}>
                <b>ATENDIMENTOS</b>
                <p>14.567</p>
            </Styled.Card>
            <Styled.Card item xs={6} lg={2}>
                <b>PROFISSIONAIS</b>
                <p>43</p>
            </Styled.Card>
            <Styled.Card item xs={6} lg={2}>
                <b>PACIENTES</b>
                <p>3.243</p>
            </Styled.Card>
            <Styled.Card item xs={6} lg={2}>
                <b>AVALIAÇÃO</b>
                <p>4.8</p>
            </Styled.Card>
            <Styled.Card item xs={6} lg={2}>
                <b>FATURAMENTO TOTAL</b>
                <p>R$ 123.545,50</p>
            </Styled.Card>
        </Styled.ContainerCard>
        <Styled.Row style={{ alignItems: 'flex-start', justifyContent: 'space-between', marginTop: 10, marginBottom: 20, maxWidth: '100%' }}>
            <Styled.Box item xs={12} lg={4}>
                <h1>PLANOS</h1>
                <Styled.Row className='jc-sb mt-10'>
                    <Styled.Column>
                        <h1>Plano Intermediário</h1>
                        <p>R$ 239,90 mensal</p>
                    </Styled.Column>
                    <Styled.Button className='max-content'>Alterar</Styled.Button>
                </Styled.Row>
                <hr />
                <Styled.Row className='jc-sb'>
                    <p>Periodicidade: Mensal</p>
                    <img src={EditOrange} alt="Edit" />
                </Styled.Row>
                <hr />
                <Styled.Row className='jc-sb'>
                    <p>Data de vencimento: dia 10 do Mês</p>
                    <img src={EditOrange} alt="Edit" />
                </Styled.Row>
                <hr />
                <Styled.Row style={{ justifyContent: 'space-between' }}>
                    <p>Forma de pagamento: Boleto Bancário</p>
                    <img src={EditOrange} alt="Edit" />
                </Styled.Row>
                <hr />
                <p>Data de Expiração: 10 de julho de 2022</p>
                <hr />
                <p>Valor a pagar: <span className='valor'>R$ 239,00</span></p>
                <hr />
                <p>Email para cobrança: carlosbarbosa@gmail.com</p>
                <hr />
                <p>Endereço de cobrança:</p>
                <p>Avenida Conceição, 321 sala 13 - Alto da Costa - São</p>
                <p>Paulo CEP: 58000-000</p>
            </Styled.Box>
            <Styled.Box item xs={12} lg={4}>
                <h1>LICENÇA / PAGAMENTOS</h1>
                <Styled.Table className='mt-5'>
                    <thead>
                        <tr>
                            <th scope="col">Data</th>
                            <th scope="col">Valor</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataLicensePayments.map((item, index) => {
                                return (<tr key={index}>
                                    <td>{item.data}</td>
                                    <td style={{ color: '#6fcf97' }}>{item.valor}</td>
                                    <td style={{ color: item.status === 'Pago' ? '#6fcf97' : '#828282' }}>{item.status}</td>
                                    <td style={{ display: 'flex', alignItems: 'flex-start', gap: 5 }}>
                                        <img src={QrCode} alt="QR Code" />
                                        <img src={EditOrange} alt="edit" />
                                    </td>
                                </tr>)
                            })
                        }
                    </tbody>
                </Styled.Table>
            </Styled.Box>
            <Styled.Box item xs={12} lg={4}>
                <h1>TICKETS DE ATENDIMENTO</h1>

                <b className='mt-10 f14'>Revisar o cadastro do cliente, pois está apresentando inconsistência nas informações do cliente.</b>
                <p className='mt-5'><b>Status:</b> Aberto</p>
                <hr />
                <b className='f14'>Revisar o cadastro do cliente, pois está apresentando inconsistência nas informações do cliente.</b>
                <p className='mt-5'><b>Status:</b> Aberto</p>
                <hr />
                <b className='f14'>Revisar o cadastro do cliente, pois está apresentando inconsistência nas informações do cliente.</b>
                <p className='mt-5'><b>Status:</b> Aberto</p>
                <hr />
                <b className='f14'>Revisar o cadastro do cliente, pois está apresentando inconsistência nas informações do cliente.</b>
                <p className='mt-5'><b>Status:</b> Aberto</p>
            </Styled.Box>

            <Styled.Box item xs={12} lg={4}>
                <h1>ATENDIMENTOS RECENTES</h1>

                <Styled.Table className='mt-10'>
                    <thead>
                        <tr>
                            <th scope="col">Data</th>
                            <th scope="col">Paciente</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataRecentServices.map((item, index) => {
                                return (<tr key={index}>
                                    <td>{item.data}</td>
                                    <td>{item.paciente}</td>
                                    <td>{item.status}</td>
                                    <td><img src={EditOrange} alt="edit" /></td>
                                </tr>)
                            })
                        }
                    </tbody>
                </Styled.Table>
            </Styled.Box>
            <Styled.Box item xs={12} lg={4}>
                <h1>SERVIÇOS DISPONIBILIZADOS</h1>

                <Styled.Table className='mt-10'>
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataServicesAvailable.map((item, index) => {
                                return (<tr key={index}>
                                    <td>{item.nome}</td>
                                    <td><img src={EditOrange} alt="edit" /></td>
                                </tr>)
                            })
                        }
                    </tbody>
                </Styled.Table>
            </Styled.Box>
            <Styled.Box item xs={12} lg={4}>
                <h1>PROFISSIONAIS CADASTRADOS</h1>

                <Styled.Table className='mt-10'>
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Cargo</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataRegisteredProfessionals.map((item, index) => {
                                return (<tr key={index}>
                                    <td>{item.nome}</td>
                                    <td>{item.cargo}</td>
                                    <td><img src={EditOrange} alt="edit" /></td>
                                </tr>)
                            })
                        }
                    </tbody>
                </Styled.Table>
            </Styled.Box>

            <MUIDataTable
                title={"NÚMEROS E ESTATÍSTICAS"}
                className="table-styles"
                data={dataNumbersStatistics}
                columns={columns}
                options={options}
            />
        </Styled.Row>
    </Styled.Container>;
};

export default ViewScreen;
