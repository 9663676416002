import { createGlobalStyle } from 'styled-components';
import SFProText from '../assets/fonts/SFProText.ttf';
import SFProDisplay from '../assets/fonts/SFProDisplay.ttf';
import SFProDisplayBold from '../assets/fonts/SFProDisplay-Bold.ttf';


const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "SFProText";
        src: local("SFProText"),
        url(${SFProText}) format("woff");
    }
    
    @font-face {
        font-family: "SFProDisplay";
        src: local("SFProDisplay"),
        url(${SFProDisplay}) format("woff");
    } 
    
    @font-face {
        font-family: "SFProDisplay-Bold";
        src: local("SFProDisplay-Bold"),
        url(${SFProDisplayBold}) format("woff");
    } 
  :root {
    --type-first: "SFProText";
    /* default's */
    --ommed: #2D313D;
    --bg-light: #F1F5F8;
    --bg-darklighter: #A2C0D4;
    --bg-dark: #3D4766;
    --main-light: #FF6A33;
    --main: #FF4500;
    --main-dark: #E63E00;
    /* admin e pacientes */
    --main-paciente: #2F80ED;
    --main-light-paciente: #2D9CDB;
    --main-lighter-paciente: #56CCF2;
    --gradiente-paciente-wk: -webkit-linear-gradient(180deg, #339BE1 0%, #2F80ED 71.35%);
    --gradiente-paciente: linear-gradient(180deg, #339BE1 0%, #2F80ED 71.35%);
    --gradiente-main-wk: -webkit-linear-gradient(177.46deg, #FF6A33 -9.25%, #FF4500 62.16%, #E53E00 158.71%);
    --gradiente-main: linear-gradient(177.46deg, #FF6A33 -9.25%, #FF4500 62.16%, #E53E00 158.71%);
  }

  * {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
  }

    
  body {
    margin: 0;
    padding: 0;
    font-family: var(--type-first);
  }

  main {
      height: 100vh;
      width: 100vw;
  }

  h1,
  h2,
  h3,
  h4,
  p {
      margin: 0px;
  }

  ul,
  li {
      list-style: none;
      padding: 0px;
      margin: 0px;
  }

  img {
      display: block;
      max-width: 100%;
  }

  button,
  input {
      font-size: 1rem;
      font-family: var(--type-first);
  }
  input:focus {
    outline: none !important;
    border-color: #f64300;
  }

  a {
      text-decoration: none;
  }

  a:link,
  a:hover,
  a:focus,
  a:active,
  a:visited {
      color: inherit;
  }

  #root {
      max-width: 100vw;
      height: 100vh;
  }
  
  input{transition:all 0.3s ease; }
  
  .container {
    height: 50px;
    width: 80%;
    margin: 0 auto;
    }   
    
    .character {
        line-height: 50px;
        font-family: "SFProDisplay";
        font-weight: 700;
        font-size: 20px;
        color: #BDBDBD;
        border-bottom: 4px solid #BDBDBD;
        margin-left: 8px;
    }

    .character:first-child {
        margin-left: 0;
    }

    .character--inactive {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .character--selected {
        box-shadow: 0 2px 5px rgb(0 0 0 / 0.2);
    }
`
export default GlobalStyle;