import React from 'react';
import * as Styled from './Styled'
import Grid from '@mui/material/Grid';

function index() {
  return <Styled.Container container>
    <Grid item lg={6} xs={12} className='item'>
      <Styled.Title>Dados Básicos</Styled.Title>
      <Styled.Input placeholder='Nome' type="text" />
      <Styled.Input placeholder='CPF' type="text" />
      <Styled.Input placeholder='RG' type="text" />
      <Styled.Input placeholder='Data de Nascimento' type="text" />
    </Grid>
    <Grid container item lg={6} xs={12} className='item'>
      <Styled.Title>Endereço</Styled.Title>
      <Styled.Input placeholder='Endereço' type="text" />
      <Styled.Input placeholder='Número' type="text" />
      <Styled.Input placeholder='Bairro' type="text" />
      <Styled.Input placeholder='Complemento' type="text" />
      <Styled.Input placeholder='Estado' type="text" />
      <Styled.Input placeholder='Cidade' type="text" />
      <Styled.Input placeholder='CEP' type="text" />
    </Grid>
    {/* <Grid item lg={4} xs={12} className='item'>
      <Styled.Title>Plano</Styled.Title>
      <Styled.Input placeholder='Plano' type="text" />
      <Styled.Input placeholder='Periodicidade' type="text" />
      <Styled.Input placeholder='Calor do plano' type="text" />
      <Styled.Input placeholder='Data de Expiração' type="text" />
      <Styled.Input placeholder='Forma de Pagamento' type="text" />
      <Styled.Input placeholder='Status de atividade' type="text" />
    </Grid>
    <Grid item lg={4} xs={12} className='item'>
      <Styled.Title>Dados de Responsável</Styled.Title>
      <Styled.Input placeholder='Nome do Responsável' type="text" />
      <Styled.Input placeholder='Telefone (Whatsapp)' type="text" />
      <Styled.Input placeholder='Email' type="text" />
      <Styled.Input placeholder='CPF' type="text" />
      <Styled.Input placeholder='Cargo na empresa' type="text" />
      <Styled.Input placeholder='Data de Nascimento' type="text" />
    </Grid>
    <Grid container item lg={8} xs={12} direction="row" className='item'>
      <Grid item lg={6} xs={12} className='item'>
        <Styled.Title>Dados tecnicos</Styled.Title>
        <Styled.Input placeholder='Token Privado' type="text" />
        <Styled.Input placeholder='Token Público' type="text" />
        <Styled.Input placeholder='Senha de acesso' type="text" />
        <Styled.Input placeholder='Data de cadastro' type="text" />
      </Grid>
      <Grid item lg={6} xs={12} className='item'>
        <Styled.Title>redes sociais</Styled.Title>
        <Styled.Input placeholder='Instagram' type="text" />
        <Styled.Input placeholder='Facebook' type="text" />
        <Styled.Input placeholder='Youtube' type="text" />
        <Styled.Input placeholder='Twitter' type="text" />
      </Grid>
      <Grid item lg={12} xs={12}>
        <Styled.TextArea placeholder='Apresentação' rows="4" />
      </Grid>
    </Grid> */}
    <Styled.Button>Editar</Styled.Button>
  </Styled.Container>;
}

export default index;
