import styled from 'styled-components'
import Grid from '@mui/material/Grid';

const Container = styled(Grid)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 98%;
  margin: 0 auto;
  .table-styles{
    margin-top: 10px;
    width: 100%;
    background: #fafafa;
    h6{color: var(--ommd); font-size: 14px;font-weight: bold;}
  }
  tr, th{
    background: #fafafa !important;
  }
  th{
    color: var(--ommed);
    font-weight: 700;
  }
  td{
    color: #757474;
    b{color: #5D5C5C;}
  }
  .active{color: #27AE60;}
  .inactive{color: #EB5757;}
`
const Row = styled.div`
    display: flex;
    align-items: center;
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  h1{
    font-family: "SFProDisplay-Bold";
    color: var(--ommed);
    margin: 1rem;
    span{color: #EB5757;}
  }
  button{background: #27AE60; font-size: 14px; font-weight: 700;}
  button:hover{background: #27AE60;}
`

const Button = styled.button`
    width: 150px;    
    height: 40px;
    border: none;
    border-radius: 10px;
    cursor: pointer;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: var(--gradiente-main-wk);  /* Chrome 10-25, Safari 5.1-6 */
    background: var(--gradiente-main); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #ff6228;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
`

const Modal = styled.div`
  background-color: #FAFAFA;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  /* Fixed position */
  left: calc(50% - 175px);
  right: calc(50% - 175px);
  position: fixed;
  top: 100px;
  padding: 20px;
  border-radius: 10px;

  /* Take full size */
  width: 350px;

  /* Displayed on top of other elements */
  z-index: 9999;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export { Container, Row, Header, Button, Modal }