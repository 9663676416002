import styled from 'styled-components'
import Grid from '@mui/material/Grid';

const Container = styled(Grid)`
  position: relative;
  display: flex;
  width: 98%;
  margin: 0 auto;
  .table-styles{
    margin-top: 10px;
    width: 100%;
    background: #fafafa;
  }
  tr, th{
    background: #fafafa !important;
  }
  th{
    color: #BDBDBD;
  }
  td{
    color: var(--ommed);
  }
  .error{
    width: 90%;
    margin: 5px 15px -5px auto;
    padding: 2px 6px;
    font-size: 12px;
    color: #f64300;
  }
`

const Box = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #F2F2F2;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 30px;
  margin-right: 12px;
  h1{
    text-transform: uppercase;
    line-height: 14px;
    color: var(--ommd);
    font-size: 14px;
  }
  input:nth-child(n+4){
    margin-top: 10px;
  }
  input:focus {
    outline: none !important;
    border: 2px solid #f64300;
  }
  input::placeholder{
    color: #BDBDBD;
  }
  .add_departamento{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto;
    h2{
      color: #828282;
      font-weight: 700;
      font-size: 14px;
      margin-left: 10%;
      margin-top: 5px;
    }
    .row{
      margin-left: 10%;
      margin-top: 5px;
      display: flex;
      align-items: center;
      label{
        margin-left: 5px;
        font-size: 16px;
        color: #828282;
      }
      input[type="checkbox"]{
        width: 16px;
        height: 16px;
      }
    }
  }
`

const Icon = styled.img``

const Card = styled(Grid)`
  background: #FAFAFA;
  min-height: 336px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  h1{
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--ommed);
  }
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  h1{
    font-family: "SFProDisplay-Bold";
    color: #2D313D;
    margin: 1rem;
    span{color: #EB5757;}
  }
`

const Button = styled.button`
    width: 180px;    
    height: 40px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 5%;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: var(--gradiente-main-wk);  /* Chrome 10-25, Safari 5.1-6 */
    background: var(--gradiente-main); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #ff6228;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
`

const Table = styled.table`
  width: 90%;
  margin: auto;
  font-size: 14px;
  overflow-x: scroll;
  padding: 15px;
  border-collapse: collapse;
  text-align: left;
  tr{
    border-bottom: 1px solid #F2F2F2;
  }
  th{
    font-weight: 400;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #F2F2F2;
  }
  td{
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #F2F2F2;
  }
  .grupos{
    display: flex;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
    p{
      text-align: center;
      width: calc(100% / 2 - 20px);
      color: var(--ommed);
      font-size: 12px;
      background: #E0E0E0;
      padding: 5px;
      border-radius: 5px;
      margin-right: 5px;
    }
  }
`

const Input = styled.input`
  padding: 15px;
  width: 90%;
  margin: auto;
  border-radius: 10px;
  border: none;
  border: 2px solid #F2F2F2;
  color: #BDBDBD;
`

export { Container, Table, Header, Box, Card, Button, Input, Icon }