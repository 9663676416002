import styled from 'styled-components'

const ErrorMessage = styled.p`
    color: #FF6A33;
    font-size: 14px;
    text-align: left;
    padding-left: 12px;
    margin-top: 4px;
    margin-bottom: 5px;
    &:nth-child(4) {
        margin-top: 8px;
    }

`

const Button = styled.button`
    width: 60%;    
    margin: 0 auto;
    padding: 15px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 30px;
    transition:all 0.5s ease;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #ff6228;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        font-style: italic;
    }
`

const Esqueci = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    color: #FF4500;
    font-weight: bold;
    .mr-5{margin-right:5px;}
    cursor: pointer;
    width: max-content;
    margin-top: 15px;
`

const Input = styled.input`
    width: 96%;
    border: 2px solid #F2F2F2;
    border-radius: 10px;
    padding: 15px;
    transition:all 0.3s ease;
`

export { ErrorMessage, Button, Esqueci, Input }
