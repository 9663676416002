import styled from 'styled-components'

const Container = styled.div`
    .not{
        color: #FF6A33;
    }
    .number{
        margin-top: 30px;
    }
    .mt-20{
        margin-top: 20px;
    }
    .mb-15{
        margin-bottom: -15px;
    }
`

const Message = styled.p`
    font-family: SFProText;
    color: #828282;
    font-size: 14px;
`

const Button = styled.button`
    width: 60%;    
    margin: 0 auto;
    padding: 15px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 30px;
    transition:all 0.5s ease;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #ff6228;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        font-style: italic;
    }
`

const Reenviar = styled.p`
    color: #828282;
    font-size: 15px;
    margin-top: 15px;
    text-align: center;
`

const Input = styled.input`
    width: 96%;
    border: 2px solid #F2F2F2;
    border-radius: 10px;
    padding: 15px;
    margin-top: 15px;
    transition:all 0.3s ease;
`



export { Container, Message, Button, Reenviar, Input }
