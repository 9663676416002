const {protocol, hostname, port} = window.location
const URL = protocol+ "//" + hostname + ":" + port

const icon = {
  dashboard: `${URL}/icons/dashboard.png`,
  pacientes: `${URL}/icons/pacientes.png`,
  membro_alianca: `${URL}/icons/membro_alianca.png`,
  relatorios: `${URL}/icons/relatorios.png`,
  financeiro: `${URL}/icons/financeiro.png`,
  marketing: `${URL}/icons/marketing.png`,
  tecnologia: `${URL}/icons/tecnologia.png`,
  integracoes: `${URL}/icons/integracoes.png`,
  usuarios: `${URL}/icons/usuarios.png`,
  logs_acesso: `${URL}/icons/logsdeacesso.png`,
  bd: `${URL}/icons/basededados.png`,
  servicos: `${URL}/icons/servicos.png`,
  plataformas: `${URL}/icons/plataformas.png`
}


const menuItems = [
  { name: 'Dashboard', icon: icon.dashboard, routes: '/admin/dashboard', submenu: [] },
  {
    name: 'Pacientes', icon: icon.pacientes, routes: '/admin/pacientes', submenu: [
      { name: 'Cadastros de pacientes', routes: '/admin/pacientes/cadastros-pacientes' }
    ]
  },
  {
    name: 'Membro da Aliança', icon: icon.membro_alianca, routes: '/admin/membro-alianca', submenu: [
      { name: 'Contratos', routes: '/admin/membro-alianca/contratos' },
      { name: 'Autorizar cadastro', routes: '/admin/membro-alianca/autorizar-cadastro' },
      { name: 'Cadastros autorizados', routes: '/admin/membro-alianca/cadastros-autorizados' }
    ]
  },
  { name: 'Relatórios', icon: icon.relatorios, routes: '/admin/relatorios', submenu: [] },
  { name: 'Financeiro', icon: icon.financeiro, routes: '/admin/financeiro', submenu: [] },
  { name: 'Marketing', icon: icon.marketing, routes: '/admin/marketing', submenu: [] },
  { name: 'Tecnologia', icon: icon.tecnologia, routes: '/admin/tecnologia', submenu: [] },
  { name: 'Integrações', icon: icon.integracoes, routes: '/admin/integracoes', submenu: [] },
  { name: 'Usuários', icon: icon.usuarios, routes: '/admin/usuarios', submenu: [] },
  { name: 'Logs de acesso', icon: icon.logs_acesso, routes: '/admin/logs-acesso', submenu: [] },
  { name: 'Base de Dados', icon: icon.bd, routes: '/admin/base-dados', submenu: [] },
  { name: 'Serviços', icon: icon.servicos, routes: '/admin/servicos', submenu: [] },
  {
    name: 'Plataformas', icon: icon.plataformas, routes: '/admin/plataformas', submenu: [
      { name: 'Automação', routes: '/admin/plataformas/automacao' },
      { name: 'Configurações', routes: '/admin/plataformas/configuracoes' },
      { name: 'Logs', routes: '/admin/plataformas/logs' },
      { name: 'Mensagens', routes: '/admin/plataformas/mensagens' }
    ]
  }
]

export { menuItems }
