import React, { useState } from 'react'
import * as Styled from './Styled'

function CardForgotPassword({ setScreen, dados, setDados }) {
    const [showModal, setShowModal] = useState(false);

    const inputData = (e) => {
        setDados(prevState => { return { ...prevState, [e.target.name]: e.target.value } })
    }

    const submit = () => {
        setShowModal(true)
    }

    return (
        <>
            {showModal && <Styled.Modal>
                <h1>Email enviado com sucesso!</h1>
                <hr />
                <p>Prontinho, agora acesse o seu email e siga os passos para criar uma nova senha de acesso.</p>
                <Styled.Button onClick={() => {
                    setShowModal(false)
                    setScreen("Token")
                }}>
                    Ok
                </Styled.Button>
            </Styled.Modal>}
            <Styled.Message>
                Sem Pânico, ok? Vamos resolver isso.
            </Styled.Message>
            <Styled.Message>
                Informe o seu email que eviaremos um link para você redefinir a sua senha.
            </Styled.Message>
            <Styled.Input
                name="user"
                defaultValue={dados.user}
                placeholder='Email'
                type="email"
                className='email'
                onChange={inputData}
            />
            <Styled.Button onClick={submit}>
                Enviar
            </Styled.Button>
        </>
    )
}

export default CardForgotPassword
