import { useState, useContext, Fragment } from 'react'
import * as Styled from './Styled'
import { menuItems as menu } from './data'
import { IconButton, InputAdornment } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import { GeneralContext } from '../../GeneralContext'
import { useNavigate } from 'react-router-dom';
import {
  SubMenu,
  SubMenuActive
} from '../../assets/imgs/icons'

function Menu({ menuIsOpen, setMenuIsOpen }) {
  const history = useNavigate();
  const { selectedMenu, setSelectedMenu } = useContext(GeneralContext)

  const [values, setValues] = useState({
    search: ''
  })

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleMenuClick = (menu, routes) => () => {
    if (selectedMenu === menu) {
      setSelectedMenu('')
    } else {
      setSelectedMenu(menu)
      if (routes) {
        history(routes)
      }
    }
  }

  const handleClickSearch = () => {
    alert(values.search)
  }

  return (
    <Styled.Menu menuIsOpen={menuIsOpen} >
      {
        <Styled.Search
          id='search'
          value={values.search}
          placeholder='#codigo'
          onChange={handleChange('search')}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='Search'
                onClick={handleClickSearch}
                edge='end'
                style={{ color: 'red' }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          aria-describedby='outlined-search'
          size='small'
          inputProps={{
            'aria-label': 'search'
          }}
        />
      }
      <Styled.MenuList menuIsOpen={menuIsOpen}>
        {menu.map(({ name, icon, routes, submenu }) => {
          if (submenu.length >= 1) {
            return (<Fragment key={name}>
              <Styled.MenuItem
                actived={selectedMenu === name}
                onClick={handleMenuClick(name, false)}
              >
                <Styled.Icon>
                  <Styled.IconMenu src={icon} alt={name} />
                </Styled.Icon>
                {name}
              </Styled.MenuItem>
              {selectedMenu === name && submenu.map((sub) => {
                return <Styled.SubItem key={sub.name} onClick={() => history(sub.routes)} actived={window.location.pathname === sub.routes}>
                  <img src={window.location.pathname === sub.routes ? SubMenuActive : SubMenu} alt="iconSubMenu" /> {sub.name}
                </Styled.SubItem>
              })}
            </Fragment>
            )
          } else {
            return (
              <Styled.MenuItem
                key={name}
                actived={selectedMenu === name}
                onClick={handleMenuClick(name, routes)}
              >
                <Styled.Icon>
                  <Styled.IconMenu src={icon} alt={name} />
                </Styled.Icon>
                {name}
              </Styled.MenuItem>
            )
          }

        })}
      </Styled.MenuList>
    </Styled.Menu>
  )
}

export default Menu
