import styled from 'styled-components'
import Grid from '@mui/material/Grid';

const Container = styled(Grid)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 98%;
  margin: 0 auto;
  .table-styles{
    margin-top: 10px;
    width: 100%;
    background: #fafafa;
  }
  tr, th{
    background: #fafafa !important;
  }
  th{
    color: #BDBDBD;
  }
  td{
    color: var(--ommed);
  }
`

const ContainerCard = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 10px;
  padding: 0;
  margin-top: 15px;
  max-width: 100%;
  @media(max-width: 1199px){
    flex-wrap: wrap;
  }
`

const Card = styled(Grid)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  
  max-width: calc(100% / 2 - 5px) !important;
  color: var(--ommed);
  border: 1px solid #E0E0E0;
  border-radius: 10px;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  align-items: center;
  .green{background: #27AE60;}
  .orange{background: #F2994A;}
  .greeninative{background: #6FCF97;}
  .red{background: #EB5757;}
  .darklighter{background: var(--bg-darklighter);}
  h1{font-size: 1.4rem;margin: 0 !important;}
  .status{font-size: 12px; padding-top: 15px;}
  .ativo{color: #27AE60;}
  img{cursor: pointer;}
  .table-styles{
    margin-top: 0px; 
    h6{
      font-size: 14px !important;
      font-weight: bold;
    }
  }
  @media(max-width: 672px){
    button{
      width: 100%;
    }
  }
`

const Column = styled(Grid)`
  display: flex;
  flex-direction: column;
  .tr{text-align: right;font-size: 11px;}
  p{margin-bottom: 5px; .red{color: #eb5757;} .blue{color: #339be1;}}
`

const Box = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px;
  min-height: 360px;

  h1{
    font-size: 14px;
  }
  hr{border-color: #E0E0E0 !important; width: 100%; opacity: .2;}
  .valor{color: #27ae60;}
  p{font-size: 13px;}
  .mt-5{margin-top: 5px;}
  .mt-10{margin-top: 10px;}
  .f14{font-size: 14px;}
  .jc-sb{justify-content: space-between;}
  .max-content{width: max-content !important;}
  @media(min-width: 1200px){
    max-width: calc(100% / 3 - 10px);
  }
`

const BoxTable = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  min-height: 360px;

  h1{
    font-size: 14px;
  }
  hr{border-color: #E0E0E0 !important; width: 100%; opacity: .2;}
  .valor{color: #27ae60;}
  p{font-size: 13px;}
  .mt-5{margin-top: 5px;}
  .mt-10{margin-top: 10px;}
  .f14{font-size: 14px;}
  .jc-sb{justify-content: space-between;}
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  h1{
    font-family: "SFProDisplay-Bold";
    color: var(--ommed);
    margin: 1rem;
    span{color: #EB5757;}
  }
`

const Button = styled.button`
    height: 30px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    padding: 0px 15px;

    color: #fff;
    font-weight: 500;
    background: #f64300;  /* fallback for old browsers */
    background: var(--gradiente-main-wk);  /* Chrome 10-25, Safari 5.1-6 */
    background: var(--gradiente-main); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #ff6228;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
`

const ID = styled.p`
  padding-left: 25px;
  color: #BDBDBD;
`

const Table = styled.table`
  width: 100%;
  font-size: 14px;
  overflow-x: scroll;
  padding: 15px;
  border-collapse: collapse;
  text-align: left;
  box-shadow: none !important;

  tr{
    border-bottom: 1px solid #F2F2F2;
  }
  th{
    color: #464545;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #F2F2F2;
  }
  td{
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #F2F2F2;
    color: #757474;
  }
  
`

export { Container, ContainerCard, Card, Box, BoxTable, Row, Column, Header, Button, ID, Table }