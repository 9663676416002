const columns = [
    {
        name: "nome",
        label: "NOME",
        options: {
            filter: true,
            sort: false,
            customBodyRender: (value) => {
                return (
                    <b>{value}</b>
                );
            }
        }
    },
    {
        name: "cod_contrato",
        label: "CÓDIGO DO CONTRATO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "tel_contato",
        label: "TELEFONE PARA CONTATO",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "responsavel",
        label: "RESPONSÁVEL",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "cpf_cnpj",
        label: "CPF / CNPJ",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "natureza",
        label: "NATUREZA",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "valor",
        label: "VALOR",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "actions",
        label: " ",
        options: {
            filter: true,
            sort: false,
        }
    },
];

const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    viewColumns: false,
};

export {
    columns,
    options
}