import React from 'react'
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import Login from './components/Login'

// const PrivateRoute = ({ children }) => {
//     const isAuthenticated = localStorage.getItem("token") !== null
//     console.log("isAuth: ", isAuthenticated)
//     return isAuthenticated ? children : <Login />
// }

const index = () => {
    const theme = createTheme();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/admin/*" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/" element={<Login />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </StyledEngineProvider >
    )
}

export default index
