import React, {
  useState, useEffect, useRef, useCallback, useMemo
} from 'react'
import ReactDOM from 'react-dom';
import { PDFObject } from 'react-pdfobject'
import * as Styled from './Styled'
import { useNavigate } from 'react-router-dom';
import {
  ApproveProfessionalRequest,
  ListProfessionalsRequest
} from '../../../../core/grpc/generated/professionalProfile_pb'
import { approveProfessionalService } from '../../../../core/services/profissionalService'
import { listProfessionalsService } from '../../../../core/services/profissionalService'
import Action from './Action'
import TableScreen from './TableScreen'
import CardScreen from './CardScreen'
import filePdf from '../../../../assets/documento.pdf'
import FooterPdf from './FooterPdf'
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import {
  Table,
  TableActive,
  Card,
  CardActive,
  Close,
  Anexo,
  AnexoInative
} from '../../../../assets/imgs/icons'

const AutorizeRegistration = () => {
  const history = useNavigate();
  const mountedRef = useRef(true)
  const [data, setData] = useState([])
  const [screen, setScreen] = useState('table');
  const [user, setUser] = useState('');
  const [modal, setModal] = useState(false);
  const [view, setView] = useState(false);
  const [lightBox, setLightBox] = useState({
    status: false,
    url: ''
  })
  const BUCKET_URL = process.env.REACT_APP_BUCKET_URL
  const [alert, setAlert] = useState({
    status: false,
    screen: ''
  });
  const arr = useMemo(() => [], [])

  const modalBody = () => (
    <div
      style={{
        backgroundColor: '#fff',

        /* Fixed position */
        left: 0,
        position: 'fixed',
        top: 0,

        /* Take full size */
        height: '100%',
        width: '100%',

        /* Displayed on top of other elements */
        zIndex: 9999,

        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <PDFObject url={filePdf} width='100vw' height='calc(100vh - 50px)' />
      <FooterPdf setView={setView} />
    </div>
  );

  const changeUser = (id) => {
    setUser(id)
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
    setUser('')
  }

  const list = useCallback(async () => {
    var request = new ListProfessionalsRequest();
    const response = await listProfessionalsService(request)
    console.log(response)
    const { success, errormessage } = response

    if (success) {
      response.professionalsList.forEach((item, i) => {
        arr[i] = {
          nome: `${item.firstname} ${item.lastname}`,
          cpf: item.socialsecuritynumber,
          rg: item.identitynumber,
          data_nascimento: item.birthdate,
          endereco: `${item.address.city} - ${item.address.state}`,
          numero: item.address.number,
          complemento: item.address.complement,
          cidade_uf: `${item.address.city} - ${item.address.state}`,
          conselho: item.councilsList,
          email: item.email,
          telefone: item.phonenumber,
          data_cadastro: item.registerdate,
          documento_frente: BUCKET_URL + item.documentpath,
          documento_verso: BUCKET_URL + item.documentbackpath,
          id_solicitacao: item.id,
          action: <Action id={i} changeUser={changeUser} />
        }
      })
      if (mountedRef.current) {
        setData(arr)
      }
    } else {
      if (errormessage === "Não autorizado") {
        localStorage.removeItem("token")
        localStorage.removeItem("phone")
        localStorage.removeItem("email")
        localStorage.removeItem("date")
        history('/login')
      }
    }
  }, [arr, history, BUCKET_URL])

  useEffect(() => {
    list()
    return () => {
      mountedRef.current = false
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Column>
          <h1>Membros da Aliança</h1>
          <h2>Autorização de cadastro</h2>
        </Styled.Column>

        <Styled.Row className='gap-5'>
          <img src={screen === "table" ? TableActive : Table} alt="" onClick={() => setScreen('table')} />
          <img src={screen === "card" ? CardActive : Card} alt="" onClick={() => setScreen('card')} />
        </Styled.Row>

        <div style={{ marginTop: 20, width: '100%' }}>
          {screen === "table" && <TableScreen data={data} />}
          {screen === "card" && <CardScreen screen={screen} data={data} />}
        </div>
        {modal && <Styled.Modal>
          <Styled.Row className='sb ai_center'>
            <Styled.Column>
              <b className='fs-12'>DADOS DO MEMBRO DA ALIANÇA</b>
              <p className='fs-12'>ID: {data[user].id_solicitacao}</p>
            </Styled.Column>
            <img src={Close} alt="close" onClick={closeModal} />
          </Styled.Row>

          <Styled.Row className='sb ai_center mt-10'>
            <p>Nome e Sobrenome</p>
            <b>{data[user].nome}</b>
          </Styled.Row>
          <hr />

          <Styled.Row className='sb ai_center'>
            <p>CPF</p>
            <b>{data[user].cpf}</b>
          </Styled.Row>
          <hr />

          <Styled.Row className='sb ai_center'>
            <p>RG</p>
            <b>{data[user].rg}</b>
          </Styled.Row>
          <hr />

          <Styled.Row className='sb ai_center'>
            <p>Data de Nascimento</p>
            <b>{data[user].data_nascimento}</b>
          </Styled.Row>
          <hr />

          <Styled.Row className='sb ai_center'>
            <p>Endereço</p>
            <b>{data[user].endereco}</b>
          </Styled.Row>
          <hr />

          <Styled.Row className='sb ai_center'>
            <p>Número</p>
            <b>{data[user].number}</b>
          </Styled.Row>
          <hr />

          <Styled.Row className='sb ai_center'>
            <p>Complemento</p>
            <b>{data[user].complement}</b>
          </Styled.Row>
          <hr />

          <Styled.Row className='sb ai_center'>
            <p>Cidade - Estado</p>
            <b>{data[user].cidade_uf}</b>
          </Styled.Row>
          <hr />

          <Styled.Row className='sb ai_center'>
            <p>Conselho</p>
            {data[user].conselho.map((csl, index) => {
              return (
                <b key={index}>{csl.name}</b>
              )
            })}
          </Styled.Row>
          <hr />

          <Styled.Row className='sb ai_center'>
            <p>Registro</p>
            {data[user].conselho.map((csl, index) => {
              return (
                <b key={index}>{csl.number}</b>
              )
            })}
          </Styled.Row>
          <hr />

          <Styled.Row className='sb ai_center'>
            <p>Email</p>
            <b>{data[user].email}</b>
          </Styled.Row>
          <hr />

          <Styled.Row className='sb ai_center'>
            <p>Telefone</p>
            <b>{data[user].telefone}</b>
          </Styled.Row>
          <hr />

          <Styled.Row className='sb ai_center'>
            <p>Data de cadastro</p>
            <b>{data[user].data_cadastro}</b>
          </Styled.Row>
          <hr />

          <b>DOCUMENTOS ANEXADOS</b>
          <Styled.Row className='mt-10 gap-5'>
            <Styled.Anexo onClick={() => setLightBox({
              status: true,
              url: data[user].documento_frente
            })
            } >
              <img src={Anexo} alt="Anexo" />
              <p>Frente</p>
            </Styled.Anexo>
            <Styled.Anexo onClick={() => setLightBox({
              status: true,
              url: data[user].documento_verso
            })
            }>
              <img src={Anexo} alt="Anexo" />
              <p>Verso</p>
            </Styled.Anexo>
            <Styled.Anexo>
              <img src={AnexoInative} alt="Anexo" />
              <p className='inativo'>Carteira do conselho</p>
            </Styled.Anexo>
          </Styled.Row>
          <Styled.Row className='groupBtn mt-10'>
            <Styled.Button className='blue' onClick={() => setView(true)}>
              CONTRATO
            </Styled.Button>
            <Styled.Button onClick={() => {
              setAlert({
                status: true,
                screen: 'negar'
              })
            }}>
              NEGAR
            </Styled.Button>
            <Styled.Button className='green' onClick={() => {
              setAlert({
                status: true,
                screen: 'autorização'
              })
            }}>
              AUTORIZAR
            </Styled.Button>
          </Styled.Row>
          {(alert.status && alert.screen === "autorização") && <Styled.Alert>
            <b>Autorização</b>
            <hr />
            <p>Tem certeza que deseja autorizar esse cadastro?</p>
            <Styled.Row className='ai_center mt-10 gap-5'>
              <p className='txtOrange' onClick={() => setAlert({ status: false, screen: '' })}>Cancelar</p>
              <Styled.Button onClick={async () => {
                var request = new ApproveProfessionalRequest();
                request.setProfessionalid(data[user].id_solicitacao)
                const response = await approveProfessionalService(request)
                console.log(response)
                setAlert({ ...alert, screen: 'autorizado' })
                list()
              }}>Sim</Styled.Button>
            </Styled.Row>
          </Styled.Alert>}

          {(alert.status && alert.screen === "autorizado") && <Styled.Alert>
            <b>Autorização</b>
            <hr />
            <p>Cadastro liberado. O profissional irá receber uma notificação via email sobre a aprovação de seus dados e liberação de uso na plataforma.</p>
            <p className='txtOrange' onClick={() => {
              setAlert({
                status: false,
                screen: ''
              })
              setModal(false)
            }}>Fechar</p>
          </Styled.Alert>}

          {(alert.status && alert.screen === "negar") && <Styled.Alert>
            <b>Negar Autorização</b>
            <hr />
            <p>Tem certeza que deseja negar o cadastro de <b>{data[user].nome}</b>?</p>
            <Styled.Row className='ai_center mt-10 gap-5'>
              <p className='txtOrange' onClick={() => setAlert({ status: false, screen: '' })}>Cancelar</p>
              <Styled.Button onClick={() => {
                setAlert({ status: false, screen: '' })
                setModal(false)
              }}>Sim</Styled.Button>
            </Styled.Row>
          </Styled.Alert>}
        </Styled.Modal>}
        {
          view && ReactDOM.createPortal(modalBody(), document.body)
        }
      </Styled.Header>

      {lightBox.status && <Lightbox image={lightBox.url} onClose={() => setLightBox(prevState => { return { ...prevState, status: false, url: '' } })} />}
    </Styled.Container>
  )
}

export default AutorizeRegistration
