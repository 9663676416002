import React, { useState } from 'react'
import * as Styled from './Styled'
import TableScreen from './TableScreen'
import NewScreen from './NewScreen'
import EditScreen from './EditScreen'

const screen = 'Table'

const PatientRegistration = () => {
    // const [screen, setScreen] = useState('Table');
    const [modal, setModal] = useState(false);

    return (
        <Styled.Container>
            <Styled.Header>
                <h1>{screen === 'New' && <span>{"{ Novo } "}</span>} Pacientes</h1>
                {/* <Styled.Button onClick={() => setScreen('New')}>Adicionar novo</Styled.Button> */}
            </Styled.Header>
            {(screen === 'Table') && <TableScreen modal={modal} setModal={setModal} />}
            {(screen === 'New') && <NewScreen />}
            {(screen === 'Edit') && <EditScreen />}
        </Styled.Container>
    )
}

export default PatientRegistration;
