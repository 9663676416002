import React, { useState } from 'react'
import * as Styled from './Styled'
import VerificationInput from "react-verification-input";

function CardTokenConfirmation({ setScreen }) {
    const [erromessage, setErroMessage] = useState(false)
    const [tkn, setTkn] = useState('')

    const submit = () => {
        if (tkn === "111111") {
            setScreen("ChangePassword")
        }else{
            setErroMessage(true)
        }
    }

    return (
        <Styled.Container>
            <Styled.Message>
                Enviamos um novo token para o seu email.
            </Styled.Message>
            <Styled.Message>
                Digite o código que recebido.
            </Styled.Message>

            <Styled.Message className='number'>
                Código enviado para tal***@ommed.com
            </Styled.Message>
            <Styled.Message className='not'>
                não é seu email?
            </Styled.Message>
            <VerificationInput
                removeDefaultStyles
                onChange={(value) => setTkn(value)}
                classNames={{
                    container: "container",
                    character: "character",
                    characterInactive: "character--inactive",
                    characterSelected: "character--selected",
                }}
            />
            {
                erromessage && <Styled.Message className='not mt-20 mb-15'>
                    Código digitado está incorreto. Enviamos um novo código para seu email
                </Styled.Message>
            }
            <Styled.Button onClick={submit}>
                Enviar
            </Styled.Button>
            <Styled.Reenviar>
                Reenviar (56)
            </Styled.Reenviar>
        </Styled.Container>
    )
}

export default CardTokenConfirmation
