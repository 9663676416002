import React from 'react';
import * as Styled from './Styled'
import MUIDataTable from "mui-datatables";
import Actions from '../Actions'
// import { ListProfessionalsRequest } from '../../../../../core/grpc/generated/professionalProfile_pb'
// import { listProfessionalsService } from '../../../../../core/services/listProfessionalsService'
import { columns, options } from '../data'
import {
    Anexo,
    AnexoInative,
    Close
} from '../../../../../assets/imgs/icons'

function TableScreen({ modal, setModal }) {
    const closeModal = () => {
        setModal(false)
        console.log('fechaaa!!!')
    }

    const data = [
        {
            nome: <b>JOÃO CARLOS BATISTA</b>,
            cpf: "592.244.820-07",
            rg: "24.988.931-6",
            tel: "(11) 95454-3243",
            cidade: "São Paulo",
            estado: "São Paulo",
            actions: <Actions setModal={setModal} />
        },
        {
            nome: <b>JOÃO CARLOS BATISTA</b>,
            cpf: "592.244.820-07",
            rg: "24.988.931-6",
            tel: "(11) 95454-3243",
            cidade: "São Paulo",
            estado: "São Paulo",
            actions: <Actions setModal={setModal} />
        },
        {
            nome: <b>JOÃO CARLOS BATISTA</b>,
            cpf: "592.244.820-07",
            rg: "24.988.931-6",
            tel: "(11) 95454-3243",
            cidade: "São Paulo",
            estado: "São Paulo",
            actions: <Actions setModal={setModal} />
        },
        {
            nome: <b>JOÃO CARLOS BATISTA</b>,
            cpf: "592.244.820-07",
            rg: "24.988.931-6",
            tel: "(11) 95454-3243",
            cidade: "São Paulo",
            estado: "São Paulo",
            actions: <Actions setModal={setModal} />
        },
    ]

    // const list = async () => {
    //     var request = new ListProfessionalsRequest();
    //     const response = await listProfessionalsService(request)
    //     console.log(response)
    //     response.professionalsList.forEach((item, i) => {
    //         arr[i] = {
    //             nome: item.firstname,
    //             cpf: item.socialsecuritynumber,
    //             rg: item.identitynumber,
    //             tel: item.socialsecuritynumber,
    //             cidade: "",
    //             estado: "",
    //             status: item.isApproved === 0 ? 'Ativo' : 'Inativo',
    //             action: <Actions setScreen={setScreen} setView={setView} />
    //         }
    //     })
    //     setData(arr)
    // }

    // useEffect(() => {
    //     list()
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return <>
        <MUIDataTable
            className="table-styles"
            data={data}
            columns={columns}
            options={options}
        />
        {modal && <Styled.Modal>
            <Styled.Row className='sb ai_center'>
                <Styled.Column>
                    <b className='fs-12'>DADOS DO PACIENTE</b>
                    <p className='fs-12'>ID: 123431235</p>
                </Styled.Column>
                <img src={Close} alt="close" onClick={closeModal} />
            </Styled.Row>
            <Styled.Row className='sb ai_center mt-10'>
                <p>Nome e Sobrenome</p>
                <b>João Carlos Batista</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>CPF</p>
                <b>043.945.056-23</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>RG</p>
                <b>654432-4</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>Data de Nascimento</p>
                <b>04/10/1980</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>CEP</p>
                <b>58098-456</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>Endereço</p>
                <b>Av. Juscelino Kubitschek</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>Número</p>
                <b>345</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>Complemento</p>
                <b>Apto 406</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>Cidade - Estado</p>
                <b>São Paulo - SP</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>Email</p>
                <b>paulo@amorim@hotmail.com</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>Telefone</p>
                <b>(11) 9 8765-4567</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>Nome do Pai</p>
                <b>Carlos Barcelos Assis</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>Nome da Mãe</p>
                <b>Maria de Fátima Assis</b>
            </Styled.Row>
            <hr />
            <Styled.Row className='sb ai_center'>
                <p>Data de cadastro</p>
                <b>11/11/2021 às 19:00:45</b>
            </Styled.Row>
            <hr />
            <b>DOCUMENTOS ANEXADOS</b>
            <Styled.Row className='mt-10 gap-5'>
                <Styled.Anexo>
                    <img src={Anexo} alt="Anexo" />
                    <p>Frente</p>
                </Styled.Anexo>
                <Styled.Anexo>
                    <img src={Anexo} alt="Anexo" />
                    <p>Verso</p>
                </Styled.Anexo>
                <Styled.Anexo>
                    <img src={AnexoInative} alt="Anexo" />
                    <p className='inativo'>Carteira do conselho</p>
                </Styled.Anexo>
            </Styled.Row>
        </Styled.Modal>}
    </>;
}

export default TableScreen;
