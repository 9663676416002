import React from 'react';
import MUIDataTable from "mui-datatables";
import { columns, options } from '../data'

function index({ data }) {
    return <MUIDataTable
        title={"TEMPLATES DE MENSAGENS E ALERTAS"}
        className="table-styles"
        data={data}
        columns={columns}
        options={options}
    />;
}

export default index;
