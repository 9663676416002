import styled from 'styled-components';

const Container = styled.div`
    margin-top: 20px;
    .table-styles{ margin-top: 20px;}
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1{font-size: 14px; color: var(--ommed); margin: 0px;}
    button{
        cursor: pointer;
        width: 196px;
        height: 29px;
        border: none;
        font-size: 10.9375px;
        font-weight: 600;
        line-height: 13px;
        color: #FAFAFA;

        /* Orange */
        background: #F2994A;
        box-shadow: 0px 0px 12.5px rgba(61, 71, 102, 0.08);
        backdrop-filter: blur(6.25px);

        /* Note: backdrop-filter has minimal browser support */
        border-radius: 8.59375px;
    }
    button:hover{background: #d77e2f;}
    input{
        border: none;
        border-radius: 10px;
        background: #F0F0F0; 
        padding: 8px 10px;
        max-width: 170px;
        font-size: 13px;
    }
    p{
        font-size: 13px;
        color: var(--ommed);
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    textarea{
        border: none;
        border-radius: 10px;
        background: #F0F0F0; 
        padding: 10px;
        font-size: 13px;
    }
    p{
        font-size: 13px;
        color: var(--ommed);
        font-weight: 600;
    }
`

const Modal = styled.div`
    background-color: #FAFAFA;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    /* Fixed position */
    left: calc(50% - 175px);
    right: calc(50% - 175px);
    position: absolute;
    top: 30px;
    padding: 20px;
    border-radius: 10px;

    /* Take full size */
    width: 350px;

    /* Displayed on top of other elements */
    z-index: 9999;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    hr{
        width: 100%;
        border-color: #F2F2F2;
        opacity: .2;
    }
    img{cursor: pointer;}
    .mt-10{margin-top: 10px;}
`

const Right = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    .blue{background: var(--gradiente-paciente-wk);background: var(--gradiente-paciente);}
    .blue:hover{background: var(--main-paciente);}
    .cancel{
        background: #E0E0E0;
        color: #828282;
    }
    .cancel:hover{
        background: #a7a7a7;
        color: #FFF;
    }
`

const TextAreaDiv = styled.div`
    padding: 15px;
    background: #F3F3F3;
    color: var(--ommed);
    font-size: 13px;
    min-height: 80px;
    margin-top: 8px;
`

const Button = styled.button`
    height: 30px;
    font-size: 12px;
    padding: 0px 10px;
    font-weight: normal;
    border: none;
    border-radius: 10px;
    cursor: pointer;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: var(--gradiente-main-wk);  /* Chrome 10-25, Safari 5.1-6 */
    background: var(--gradiente-main); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #AE2F00;
    }
`

export {
    Container,
    Row,
    Column,
    Right,
    TextAreaDiv,
    Button,
    Modal,
}