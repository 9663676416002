import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'

const Grid = styled(MuiGrid)`
  position: relative;
  width: 100%;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  flex-wrap: nowrap !important;
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ menuIsOpen }) => (menuIsOpen ? 'calc(100vw - 260px)' : 'calc(100vw - 50px)')};
  background: #fafafa;
  overflow-y: scroll;

  h1 {
    margin: 1rem;
    // text-decoration: underline var(--default);
  }
`

export { Grid, Main }
