import React, { useState, useEffect, useContext } from 'react';
import { GeneralContext } from '../../GeneralContext'
import { Route, Routes } from 'react-router-dom'
import * as Styled from './Styled'
import { useNavigate } from 'react-router-dom';
//Utilites
import Navbar from '../../views/Navbar'
import Menu from '../../views/Menu'

//Componentes
import DashboardScreen from '../../views/Main/DashboardScreen'
import PatientRegistration from '../../views/Main/PatientsScreen/PatientRegistration';
import SubscriptionsContractsScreen from '../../views/Main/AllianceMemberScreen/SubscriptionsContractsScreen'
import AuthorizeRegistration from '../../views/Main/AllianceMemberScreen/AuthorizeRegistration'
import RegistrationProfessionals from '../../views/Main/AllianceMemberScreen/RegistrationProfessionals'

import UsersScreen from '../../views/Main/UsersScreen';
import FinancialScreen from '../../views/Main/FinancialScreen';

import PlatformsSettings from '../../views/Main/PlatformsScreen/PlatformsSettings';
import PlatformsLogs from '../../views/Main/PlatformsScreen/PlatformsLogs';

function Home() {
  const history = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(true)
  const { logged } = useContext(GeneralContext)

  useEffect(() => {
    if (!logged) {
      history('/login');
    }
  }, [history, logged]);

  return (
    <>
      <Navbar />
      <Styled.Grid container>
        <Menu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
        <Styled.Main menuIsOpen={menuIsOpen}>
          <Routes>
            <Route exact path="/" element={<h1>Rota padrão</h1>} />
            <Route path="/dashboard/" element={<DashboardScreen />} />
            
            <Route path="/pacientes/cadastros-pacientes" element={<PatientRegistration />} />

            <Route path="/membro-alianca/contratos" element={<SubscriptionsContractsScreen />} />
            <Route path="/membro-alianca/autorizar-cadastro" element={<AuthorizeRegistration />} />
            <Route path="/membro-alianca/cadastros-autorizados" element={<RegistrationProfessionals />} />

            <Route path="/usuarios" element={<UsersScreen />} />
            
            <Route path="/financeiro" element={<FinancialScreen />} />

            <Route path="/plataformas/configuracoes" element={<PlatformsSettings />} />
            <Route path="/plataformas/logs" element={<PlatformsLogs />} />
          </Routes>
        </Styled.Main>
      </Styled.Grid>
    </>
  )
}

export default Home
