import React, { useState } from 'react'
import * as Styled from './Styled'
import CardForgotPassword from './CardForgotPassword'
import CardLogin from './CardLogin'
import Logo from '../../assets/imgs/logo.png'
import CardTokenConfirmation from './CardTokenConfirmation'
import CardChangePassword from './CardChangePassword'

const Login = () => {
    const [screen, setScreen] = useState('Login');
    const [dados, setDados] = useState({
        user: '', password: ''
    })

    return (
        <Styled.Grid>
            <Styled.Container>
                <Styled.Logo src={Logo} alt="Ommed" />
                <Styled.Card>
                    <Styled.Header>
                        <Styled.Title>
                            {screen === "Login" && "Área restrita"}
                            {screen === "ForgotPassword" && "Esqueci minha senha"}
                            {screen === "Token" && "Confirmar token"}
                            {screen === "ChangePassword" && "Alteração de senha"}
                        </Styled.Title>
                    </Styled.Header>
                    <Styled.Content>
                        {screen === "Login" && <CardLogin setScreen={setScreen} dados={dados} setDados={setDados} />}
                        {screen === "ForgotPassword" && <CardForgotPassword setScreen={setScreen} dados={dados} setDados={setDados} />}
                        {screen === "Token" && <CardTokenConfirmation setScreen={setScreen} />}
                        {screen === "ChangePassword" && <CardChangePassword setScreen={setScreen} />}
                    </Styled.Content>
                </Styled.Card>
                <Styled.Footer>
                    "Investir no conhecimento produz sempre os melhores benefícios." - Benjamin Franklin
                </Styled.Footer>
            </Styled.Container>
        </Styled.Grid>
    )
}

export default Login
