import React, { useState } from 'react'
import * as Styled from './Styled'
import TableScreen from './TableScreen'
import NewScreen from './NewScreen'
import EditScreen from './EditScreen'
import ViewScreen from './ViewScreen'

const RegistrationProfessionals = () => {
    const [screen, setScreen] = useState('Table');
    const [view, setView] = useState(false);

    return (
        <Styled.Container>
            {!view && <Styled.Header>
                <h1>{screen === 'New' && <span>{"{ Novo } "}</span>}Membros da Aliança</h1>
                {/* <Styled.Button onClick={() => setScreen('New')}>Adicionar novo</Styled.Button> */}
            </Styled.Header>}
            {(screen === 'Table' && !view) && <TableScreen setScreen={setScreen} setView={setView} />}
            {(screen === 'New' && !view) && <NewScreen />}
            {(screen === 'Edit' && !view) && <EditScreen />}
            {view && <ViewScreen setView={setView} />}
        </Styled.Container>
    )
}

export default RegistrationProfessionals
