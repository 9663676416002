import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  flex-wrap: nowrap;

  background: #F2F2F2;
  padding: 0 30px;
`

const Row = styled.div`
  display: flex;
`

const Button = styled.button`
    width: 180px;    
    height: 40px;
    border: none;
    border-radius: 10px;
    cursor: pointer;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #FF6A33, #FF4500, #E53E00);  /* Chrome 10-25, Safari 5.1-6 */
    background: var(--gradiente-main); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #ff6228;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        text-shadow: 1px 1px 2px black;
    }
`

export { Container, Row, Button }