import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    hr{
        width: 100%;
        border-color: #F2F2F2;
        opacity: .2;
    }
    .mt-10{
        margin-top: 10px;
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    img{cursor: pointer;}
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    h1{
        margin: 0px;
        font-size: 14px;
        color: #2d313d;
    }
    h2{
        font-size: 13px;
        color: var(--ommed);
        font-weight: 100;
    }
    b{
        font-size: 13px;
    }
    textarea{
        background: #F3F3F3;
        border: none;
        color: #828282;
        padding: 10px;
        margin-top: 8px;
    }
    input{
        color: #828282;
        background: #F2F2F2;
        padding: 10px;
        font-size: 13px;
        border: none;
    }
    p{
        font-size: 13px !important;
        color: #828282
    }
    .mt-5{margin-top: 5px;}
`

const Row = styled.div`
    display: flex;
    width: 100%;
    
    padding: 2px 0px;
    justify-content: space-between;
    b{
        font-size: 13px;
    }
    p{
        color: #828282;
        font-size: 13px;
    }
    .txtDark{
        color: var(--ommed);
    }
    .favorecido{color: var(--main-paciente);}
`

const Status = styled.p`
    font-size: 13px;
    .active{color: #6FCF97;}
    .inactive{color: #EB5757;}
`

const Inactive = styled.p`
    font-size: 13px;
    color: #EB5757;
`

const Right = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .blue{background: linear-gradient(180deg, #339BE1 0%, #2F80ED 71.35%);}
    .blue:hover{background: var(--main-paciente);}
    .cancel{
        background: #E0E0E0;
        color: #828282;
    }
    .cancel:hover{
        background: #a7a7a7;
        color: #FFF;
    }
`

const Button = styled.button`
    padding: 0px 10px;
    font-size: 12px;    
    height: 30px;
    border: none;
    border-radius: 10px;
    margin-left: 5px;
    cursor: pointer;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: var(--gradiente-main-wk);  /* Chrome 10-25, Safari 5.1-6 */
    background: var(--gradiente-main); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #E53E00;
    }
`

const TextAreaDiv = styled.div`
    padding: 15px;
    background: #F3F3F3;
    color: var(--ommed);
    font-size: 13px;
    min-height: 80px;
    margin-top: 8px;
`

export {
    Container,
    Header,
    Column,
    Row,
    Status,
    Inactive,
    Right,
    Button,
    TextAreaDiv
}