import React from 'react';
import * as Styled from './Styled'

function FooterPdf({ setView }) {
  return <Styled.Container>
    <Styled.Button onClick={() => setView(false)}>
      Fechar
    </Styled.Button>
  </Styled.Container>;
}

export default FooterPdf;
