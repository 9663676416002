import styled from 'styled-components'
import Grid from '@mui/material/Grid';

const Container = styled(Grid)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  
  /* Grey 7 */
  background: #FAFAFA;
  @media(min-width: 815px){
    width: calc(100% / 2 - 10px);
  }
  @media(min-width: 1275px){
    width: calc(100% / 3 - 10px);
  }
  @media(max-width: 815px){
    width: 100%;
    margin-right: 15px;
  }
  
  /* Gray 5 */
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 20px 0px;
  hr{
    width: 92%;
    border-color: #F2F2F2;
    opacity: .2;
    margin-top: 14px;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  h2{
    margin: 0px 1rem;
    font-size: 16px;
  }
  p{
    font-size: 13px;
    color: var(--ommed);
  }
  b{
    margin-top: 4px;
    font-size: 13px;
    color: #4F4F4F;
  }
`
const Row = styled.div`
  display: flex;
  text-align: center;
  img{
    cursor: pointer;
  }
  .item{flex: 1;}
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  h1{
    font-family: "SFProDisplay-Bold";
    color: #2D313D;
    margin: 1rem;
    span{color: #EB5757;}
  }
  .gap-5{
    gap: 5px;
  }
`

const Button = styled.button`
    width: 180px;    
    height: 40px;
    border: none;
    border-radius: 10px;
    cursor: pointer;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #FF6A33, #FF4500, #E53E00);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #FF6A33, #FF4500, #E53E00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #ff6228;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
`

export { Container, Card, Column, Row, Header, Button }