import styled from 'styled-components'
import Grid from '@mui/material/Grid';

const Container = styled(Grid)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 98%;
  margin: 0 auto;
  .table-styles{
    margin-top: 10px;
    width: 100%;
    background: #fafafa;
  }
  tr, th{
    background: #fafafa !important;
  }
  th{
    color: var(--ommed);
  }
  td{
    color: #4F4F4F;
    font-weight: bold;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  h1{
    margin: 1rem 1rem 0px 1rem !important;
  }
  h2{
    margin: 0px 1rem;
    font-size: 16px;
  }
`

const Row = styled.div`
  display: flex;
  img{
    cursor: pointer;
  }
  .green{background: #27AE60;}
  .green:hover{background: #03863A;}
  .blue{background: #339BE1;}
  .blue:hover{background: #1B7CBD;}
  p{
    padding: 3px 0px;
  }
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  h1{
    font-family: "SFProDisplay-Bold";
    color: #2D313D;
    margin: 1rem;
    span{color: #EB5757;}
  }
  .gap-5{
    gap: 5px;
  }
  .ai_center{align-items:center;}
`

const Button = styled.button`
    height: 30px;
    font-size: 12px;
    padding: 0px 10px;
    font-weight: normal;
    border: none;
    border-radius: 10px;
    cursor: pointer;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: var(--gradiente-main-wk);  /* Chrome 10-25, Safari 5.1-6 */
    background: var(--gradiente-main); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #AE2F00;
    }
`

const Anexo = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border: none;
  background: #F6F6F6;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  color: var(--ommed);
  p{margin-left: 5px;}
  .inativo{color: #E0E0E0;}
`

const Modal = styled.div`
  position: fixed;
  top: 50px;
  left: calc(50% - 170px);
  right: calc(50% - 170px);
  width: 340px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 15px;
  z-index: 100;
  background: #FAFAFA;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-size: 13px;
  .groupBtn{
    display: flex;
    gap: 8px;
    justify-content: center;
  }
  .sb{justify-content: space-between}
  .ai_center{align-items: center;}
  .fs-12{font-size: 12px;}
  .mt-10{margin-top: 10px;}
  .gap-5{gap: 5px;}
  hr{
    border-color: #F2F2F2;
    opacity: .2;
  }
`

const Alert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: calc(50% - 80px);
  left: 45px;
  width: 240px;
  padding: 15px;
  z-index: 101;
  background: #FFF;
  box-shadow: 0px 8px 24px rgba(176, 190, 197, 0.25), 0px 3px 5px rgba(176, 190, 197, 0.3);
  backdrop-filter: blur(8px);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-size: 13px;
  hr{
    width: 100%;
    border-color: #F2F2F2;
    opacity: .2;
  }
  p{color: #828282; line-height: 1.5; text-align: center;}
  .txtOrange{font-weight: bold; color: #FF4500; cursor: pointer;}
`

export { Container, Column, Row, Header, Button, Anexo, Modal, Alert }