import React from 'react'
import * as Styled from './Styled'
import Grid from '@mui/material/Grid';
import {
    Construction,
    EditLocation,
    Celebration
} from '@mui/icons-material';

function index() {
    return (
        <Styled.Container container>
            <Grid item xs={12} md={6} lg={3}>
                <Styled.H1>JIRA TAREFAS</Styled.H1>
                <Styled.Card className="mt-15 red">
                    <h1>[ADMINISTRAÇÃO] [DESIGN] Figma Web Administração Ommed</h1>
                    <p>Tarefa dedicada para as telas de Administração da Ommed. Registrar na tarefa, o link do figma. Alinhar desejado com </p>
                </Styled.Card>
                <Styled.Card className="mt-10 yellow">
                    <h1>[ADMINISTRAÇÃO] [DESIGN] Figma Web Administração Ommed</h1>
                    <p>Tarefa dedicada para as telas de Administração da Ommed. Registrar na tarefa, o link do figma. Alinhar desejado com </p>
                </Styled.Card>
                <Styled.Card className="mt-10 gray">
                    <h1>[ADMINISTRAÇÃO] [DESIGN] Figma Web Administração Ommed</h1>
                    <p>Tarefa dedicada para as telas de Administração da Ommed. Registrar na tarefa, o link do figma. Alinhar desejado com </p>
                </Styled.Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Styled.H1>Tickets atribuídos a você</Styled.H1>
                <Styled.Card className="mt-15 gray">
                    <h1>Revisar o cadastro do cliente, pois está apresentando inconsistência nas informações do cliente.</h1>
                    <p>Cliente: Clínica Allison Morais</p>
                    <p>Status: aberto</p>
                </Styled.Card>
                <Styled.Card className="mt-10 gray">
                    <h1>Revisar o cadastro do cliente, pois está apresentando inconsistência nas informações do cliente.</h1>
                    <p>Cliente: Clínica Allison Morais</p>
                    <p>Status: aberto</p>
                </Styled.Card>
                <Styled.Card className="mt-10 gray">
                    <h1>Revisar o cadastro do cliente, pois está apresentando inconsistência nas informações do cliente.</h1>
                    <p>Cliente: Clínica Allison Morais</p>
                    <p>Status: aberto</p>
                </Styled.Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Styled.H1>Bugs e correções</Styled.H1>
                <Styled.CardOcorrencia>
                    <h1>Horas sem Ocorrências</h1>
                    <p>21 horas e 30 minutos</p>
                </Styled.CardOcorrencia>
                <Styled.Card className="mt-10 shadow">
                    <h1>Erro no cadastro de serviço</h1>
                    <p>Ocorrido a 1 dia e 5 horas</p>
                    <p>Cliente: Clinica Alisson Moraes</p>
                    <p>Bug ocorrido: Falha de autenticação</p>
                    <p>Responsável: </p>
                    <Styled.Btn>Ver detalhes</Styled.Btn>
                </Styled.Card>
                <Styled.Card className="mt-10 shadow">
                    <h1>Erro no cadastro de serviço</h1>
                    <p>Ocorrido a 1 dia e 5 horas</p>
                    <p>Cliente: Clinica Alisson Moraes</p>
                    <p>Bug ocorrido: Falha de autenticação</p>
                    <p>Responsável: </p>
                    <Styled.Btn>Ver detalhes</Styled.Btn>
                </Styled.Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Styled.H1>histórico de acessos e alterações</Styled.H1>
                <Styled.Card className="mt-10 gray">
                    <Styled.H1Historico className="mb-2">21/08/2021 às 14:35hs</Styled.H1Historico>  
                    <Styled.Row>
                        <Construction />
                        <p>Talles carrelo alterou o endereço de Clínica Abreu & Lima.</p>
                    </Styled.Row>

                    <Styled.H1Historico className="mb-2 mt-8">21/08/2021 às 14:25hs</Styled.H1Historico>  
                    <Styled.Row>
                        <Celebration />
                        <p>Talles carrelo entrou no sistema</p>
                    </Styled.Row>                   
                    
                    <Styled.H1Historico className="mb-2 mt-8">21/08/2021 às 10:15hs</Styled.H1Historico>  
                    <Styled.Row>
                        <EditLocation />
                        <p><b>Viva!</b> Cliente novo na casa: <b>Clínica João das Dores suassuna. cadastrado por Talles Carrelo</b></p>

                    </Styled.Row>                   
                    
                    <Styled.H1Historico className="mb-2 mt-8">21/08/2021 às 08:30hs</Styled.H1Historico>  
                    <Styled.Row>
                        <EditLocation />
                        <p>
                            <b>Viva!</b> Cliente novo na casa: <b>Clínica João das Dores suassuna. cadastrado por Talles Carrelo</b>
                        </p>
                    </Styled.Row>                   
                </Styled.Card>                
            </Grid>
        </Styled.Container>
    )
}

export default index
