import React, { useState } from 'react';
import * as Styled from './Styled'
import {
    Close
} from '../../../../../assets/imgs/icons'

function Modals({ modal, data, setData, setModal }) {
    const { id } = modal
    const [dados, setDados] = useState(data[id])

    return <>
        <Styled.Header>
            <Styled.Column>
                <h1 onClick={() => console.log(data[id])}>TEMPLATE</h1>
                <h2>MENSAGENS E ALERTAS</h2>
            </Styled.Column>
            <img src={Close} alt="Close" onClick={() => setModal({
                status: false,
                id: undefined,
                name: ''
            })} />
        </Styled.Header>
        <Styled.Container>
            {modal.name === "View" && <div>
                <Styled.Row>
                    <b>Assunto</b>
                    <p className='txtDark'>{dados.assunto}</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Chave</b>
                    <p>{dados.chave}</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Plataforma</b>
                    <p>{dados.plataforma}</p>
                </Styled.Row>
                <hr />
                <Styled.Row>
                    <b>Tipo de componente</b>
                    <p>{dados.tipo_componente}</p>
                </Styled.Row>
                <hr />
                <Styled.Column>
                    <b>Texto</b>
                    <Styled.TextAreaDiv>
                        Seu pedido de atualização cadastral foi feito com sucesso. Confira a atualização no App.
                    </Styled.TextAreaDiv>
                </Styled.Column>
                <hr />
                <Styled.Row>
                    <b>Data de cadastro</b>
                    <p>{dados.data_cadastro}</p>
                </Styled.Row>
                <hr />
                {dados.status ? <p className='active'>Ativo</p> : <p className='inactive'>Inativo</p>}
                <Styled.Right>
                    <Styled.Button onClick={() => setModal({
                        ...modal, name: 'Edit'
                    })}>Editar</Styled.Button>
                </Styled.Right>
            </div>}
            {modal.name === "New" && <div>
                <Styled.Column>
                    <b>Assunto</b>
                    <input type="text" placeholder='Digite o assunto' />
                </Styled.Column>

                <Styled.Column className='mt-10'>
                    <b>Plataforma</b>
                    <input type="text" placeholder='Selecione a plataforma' />
                </Styled.Column>

                <Styled.Column className='mt-10'>
                    <b>Tipo de componente</b>
                    <input type="text" placeholder='Selecione o componente' />
                </Styled.Column>

                <Styled.Column className='mt-10'>
                    <b>Texto</b>
                    <textarea placeholder='Digite o texto' rows={5} />
                </Styled.Column>

                <label className='mt-10'>
                    <input type="checkbox" /> Ativo
                </label>
                <Styled.Right className='mt-10'>
                    <Styled.Button onClick={() => {
                        let arr = data
                        arr[id] = dados
                        setModal({
                            status: false,
                            id: null,
                            name: ''
                        })
                        setData(arr)
                    }}>Salvar</Styled.Button>
                    <Styled.Button className='cancel'>Cancelar</Styled.Button>
                </Styled.Right>
            </div>}
            {modal.name === "Edit" && <div>
                <Styled.Column>
                    <b>Assunto</b>
                    <input type="text"
                        value={dados.assunto}
                        onChange={(e) => {
                            setDados({ ...dados, assunto: e.target.value })
                        }} />
                </Styled.Column>

                <Styled.Column className='mt-10'>
                    <b>Plataforma</b>
                    <input type="text"
                        value={dados.plataforma}
                        onChange={(e) => {
                            setDados({ ...dados, plataforma: e.target.value })
                        }} />
                </Styled.Column>

                <Styled.Column className='mt-10'>
                    <b>Tipo de componente</b>
                    <input type="text"
                        value={dados.tipo_componente}
                        onChange={(e) => {
                            setDados({ ...dados, tipo_componente: e.target.value })
                        }} />
                </Styled.Column>

                <Styled.Column className='mt-10'>
                    <b>Texto</b>
                    <textarea rows={5} />
                </Styled.Column>

                <label className='mt-10'>
                    <input type="checkbox" checked={dados.status} onChange={() => {
                        let value = !dados.status
                        setDados({ ...dados, status: value })
                    }} /> Ativo
                </label>
                <Styled.Right className='mt-10'>
                    <Styled.Button onClick={() => {
                        let arr = data
                        arr[id] = dados
                        arr = [...arr]
                        console.log(data)
                        console.log(arr)
                        setData(arr)
                        setModal({
                            status: false,
                            id: null,
                            name: ''
                        })
                    }}>Salvar</Styled.Button>
                    <Styled.Button className='cancel'>Cancelar</Styled.Button>
                </Styled.Right>
            </div>}
        </Styled.Container>
    </>;
}

export default Modals;
