const dataUsers = [
    {nome: 'Talles Carrelo', cargo: 'Design', departamento: 'Design'},
    {nome: 'Talles Carrelo', cargo: 'Design', departamento: 'Design'},
    {nome: 'Talles Carrelo', cargo: 'Design', departamento: 'Design'},
]

const dataDepartments = [
    {nome: 'Design', grupos: ['Marketing', 'Notícias', 'Tickets']},
    {nome: 'Financeiro', grupos: ['Finanças', 'Cobrança', 'Tickets']},
    {nome: 'Suporte', grupos: ['Tickets']},
    {nome: 'Qualidade', grupos: ['Tickets', 'Integração']},
]

const dataGroupUsers = [
    'Marketing',
    'Finanças',
    'Tickets',
    'Integração',
    'Tecnologia',
    'Cobranças',
    'Desenvolvimento'
]

export { dataUsers, dataDepartments, dataGroupUsers }