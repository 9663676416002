import React from 'react';

function Action({ id, changeUser }) {
  return <button id={`${id}`} style={{
    background: '#27AE60',
    color: '#FFF',
    width: '90%',
    maxWidth: 250,
    margin: '0 auto',
    border: 'none',
    borderRadius: 5,
    padding: "10px 14px",
    fontSize: 12,
    cursor: 'pointer'
  }} onClick={(e) => changeUser(e.target.id)}>
    VER
  </button>
}

export default Action;
