const columns = [
    {
        name: "nome",
        label: "Nome",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "cpf",
        label: "CPF",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "conselho",
        label: "Conselho - Registro",
        options: {
            filter: true,
            sort: false,
            customBodyRender: (array) => {
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        {
                            array.map((value, index) => {
                                return(
                                    <p key={index}>
                                        {value.name} - {value.number}
                                    </p>
                                )
                            })
                        }
                    </div>
                );
            }
        }
    },
    // {
    //     name: "registro",
    //     label: "Registro",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     }
    // },
    {
        name: "data_nascimento",
        label: "Data nascimento",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "cidade_uf",
        label: "Cidade-UF",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "data_cadastro",
        label: "Data de cadastro",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "action",
        label: " ",
        options: {
            filter: true,
            sort: false,
        }
    },
];

const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    viewColumns: false,
    search: false,
    download: false,
    print: false,
    filter: false,
};


export { columns, options }