const columns = [
    {
        name: "chave",
        label: "CHAVE",
        options: { filter: true, sort: false }
    },
    {
        name: "assunto",
        label: "ASSUNTO",
        options: {
            filter: true,
            sort: false,
            customBodyRender: (value) => {
                return (
                    <b>{value}</b>
                );
            }
        }
    },
    {
        name: "plataforma",
        label: "PLATAFORMA",
        options: { filter: true, sort: false }
    },
    {
        name: "tipo_componente",
        label: "TIPO DE COMPONENTE",
        options: { filter: true, sort: false }
    },
    {
        name: "data_cadastro",
        label: "DATA CADASTRO",
        options: { filter: true, sort: false }
    },
    {
        name: "status",
        label: "STATUS",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                if (value) {
                    return (
                        <p className='active'>Ativo</p>
                    );
                } else {
                    return (
                        <p className='inactive'>Inativo</p>
                    );
                }
            }
        }
    },
    {
        name: "action",
        label: "Ações",
        options: { filter: false, sort: false, }
    },
];

const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    viewColumns: false,
};


export { columns, options }