import React, { useState } from 'react'
import * as Styled from './Styled'


const CardChangePassword = ({ setScreen }) => {
    const [erromessage, setErroMessage] = useState(false)
    const [newpass, setNewPass] = useState({ new: '', confirm: '' })

    const inputData = (e) => {
        setNewPass(prevState => { return { ...prevState, [e.target.name]: e.target.value } })
    }

    const submit = () => {
        const SAME_PASSWORDS = newpass.new === newpass.confirm
        setErroMessage(true)
        if (SAME_PASSWORDS) setScreen('Login')
    }

    return (
        <>
            {(erromessage && newpass.new !== newpass.confirm) && < Styled.ErrorMessage >
                As senhas devem ser iguais.
            </Styled.ErrorMessage>}
            <Styled.Input name="new" placeholder='Nova senha' type="password" className='email' onChange={inputData} required />

            {(erromessage && newpass.new === '') && < Styled.ErrorMessage >
                Esse campo é obrigatório
            </Styled.ErrorMessage>}

            <Styled.Input name="confirm" placeholder='Senha' type="password" className='senha' onChange={inputData} required />
            {(erromessage && newpass.confirm === '') && <Styled.ErrorMessage className='second'>
                Esse campo é obrigatório
            </Styled.ErrorMessage>}
            <Styled.Button onClick={submit}>
                Salvar
            </Styled.Button>
        </>
    )
}

export default CardChangePassword
