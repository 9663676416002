import { ProfessionalProfileProtoServicePromiseClient } from '../grpc/generated/professionalProfile_grpc_web_pb'

const apiUrl = process.env.REACT_APP_API_URL;
const svc = new ProfessionalProfileProtoServicePromiseClient(apiUrl);

async function listProfessionalsService(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listProfessionals(request, metadata, {});

    return response.toObject();
}

async function listApprovedProfessionalsService(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.listApprovedProfessionals(request, metadata, {});

    return response.toObject();
}

async function approveProfessionalService(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.approveProfessional(request, metadata, {});

    return response.toObject();
}

export { 
    listProfessionalsService,
    listApprovedProfessionalsService,
    approveProfessionalService
}
