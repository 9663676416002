import { createContext, useState } from 'react'

export const GeneralContext = createContext()

export const GeneralStorage = ({ children }) => {
  const [logged, setLogged] = useState(false) // Log
  const [selectedMenu, setSelectedMenu] = useState('Membro da Aliança')

  return (
    <GeneralContext.Provider value={{ selectedMenu, setSelectedMenu, logged, setLogged }}>
      {children}
    </GeneralContext.Provider>
  )
}
