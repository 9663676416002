const columns = [
    {
        name: "nome",
        label: "Nome",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "cpf",
        label: "CPF",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "rg",
        label: "RG",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "tel",
        label: "Telefone",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "cidade",
        label: "Cidade",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "estado",
        label: "Estado",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "actions",
        label: " ",
        options: {
            filter: true,
            sort: false,
        }
    },
];

const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    viewColumns: false,
};


export { columns, options }