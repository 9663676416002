import styled from 'styled-components'
import { OutlinedInput } from '@mui/material'
import {
  ArrowBackIos,
  ArrowForwardIos
} from '@mui/icons-material';

const Search = styled(OutlinedInput)`
  margin: 1rem;
  background: #3D4666;
  color: #EB5757 !important;
`

const SearchIcon = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #3D4666;
  width: 35px !important;
  height: 35px;
  color: red;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  border-radius: 15px;
`

const Menu = styled.div`
  width: ${({ menuIsOpen }) => (menuIsOpen ? '260px' : '50px')};
  overflow-x: hidden;
  background: #fafafa;
  border-right: 1px solid #e5e5e6;
  font-weight: bolder;
  box-shadow: rgb(14 30 37 / 12%) 0px 0px 0px 0px,
    rgb(14 30 37 / 32%) -4px 11px 16px 0px;
  /* border-bottom-right-radius: 50px 20px; */

  ::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #3D4666;
    border-radius: 5px;
  }
`

const MenuList = styled.ul`
  margin: ${({ menuIsOpen }) => (menuIsOpen ? '0 1rem 1rem !important' : '0px')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin: 0 auto;
  border-left: 4px solid transparent;
  padding: 0 0.5rem;
  gap: 8px;
  cursor: pointer;
  color: ${({ actived }) => (actived ? '#EB5757' : '#2D313D')};
  
  img{
    filter: ${({ actived }) => (actived && 'invert(0%) sepia(10%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%)')};
  }
  &:hover {
    background-color: #f1f1f1;
  }
`

const SubItem = styled.li`
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  border-radius: 4px;
  margin: 0 auto;
  border-left: 4px solid transparent;
  padding: 0 1.3rem;
  gap: 8px;
  cursor: pointer;
  color: ${({ actived }) => (actived ? '#EB5757' : '#2D313D')};
  
  &:hover {
    background-color: #f1f1f1;
  }
`

const BackIcon = styled(ArrowBackIos)`
  height: 18px !important;  
  display: block !important;
  margin-left: auto !important;
  margin-right: ${({ menuisopen }) => (menuisopen === 'true' ? '12px' : '0px')};
  margin-top: 5px;
  cursor: pointer;
`

const ForwardIcon = styled(ArrowForwardIos)`
  height: 18px !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: ${({ menuisopen }) => (menuisopen === 'true' ? '12px' : '0px')};
  margin-top: 5px;
  cursor: pointer;
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
`
const IconMenu = styled.img``

export { Menu, SubItem, MenuList, MenuItem, Search, BackIcon, ForwardIcon, SearchIcon, Icon, IconMenu }
