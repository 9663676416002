import { AdminAccountProtoServicePromiseClient } from '../grpc/generated/adminAccount_grpc_web_pb'

const apiUrl = process.env.REACT_APP_API_URL;
const svc = new AdminAccountProtoServicePromiseClient(apiUrl);

async function loginService(request) {
    const response = await svc.login(request, {});
    
    return response.toObject();
}

async function registerService(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.register(request, metadata, {});

    return response.toObject();
}

async function updateInfoService(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.updateInfo(request, metadata, {});

    return response.toObject();
}

export { 
    loginService,
    registerService,
    updateInfoService
}