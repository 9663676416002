import React, { useState } from 'react';
import * as Styled from './Styled'
// import Grid from '@mui/material/Grid';
import { RegisterRequest } from '../../../core/grpc/generated/adminAccount_pb'
import { registerService } from '../../../core/services/adminService'
import {
    dataUsers,
    // dataDepartments, 
    // dataGroupUsers 
} from './data'
import { Edit } from '../../../assets/imgs/icons'

const USER_CAD = {
    nome: '',
    sobre_nome: '',
    email: '',
    telefone: '',
    senha: '',
    confirm: ''
}

function UserScreen() {
    const [erroMessage, setErroMessage] = useState({
        status: false,
        data: []
    })
    const [userCad, setUserCad] = useState(USER_CAD)

    const register = async () => {
        var request = new RegisterRequest();
        request.setFirstname(userCad.nome)
        request.setLastname(userCad.sobre_nome)
        request.setEmail(userCad.email)
        request.setPhone(userCad.telefone)
        request.setPassword(userCad.senha)
        request.setConfirm(userCad.confirm)
        const response = await registerService(request)

        console.log(response)
        if (response.success === true) {
            setUserCad(USER_CAD);
            setErroMessage({
                status: true,
                data: 'Cadastrado com sucesso'
            })
        } else {
            const { data } = response
            const dataParse = JSON.parse(data)

            console.log(dataParse)

            setErroMessage({
                status: true,
                data: dataParse
            })
        }
    }

    return <Styled.Container container item>
        <Styled.Header>
            <h1>Usuários</h1>
        </Styled.Header>
        <Styled.Box item xs={12} lg={3}>
            <Styled.Card item>
                <h1>Usuários</h1>
                <Styled.Table>
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Cargo</th>
                            <th scope="col">Departamento</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataUsers.map((row, index) => {
                                return (<tr key={index}>
                                    <td>{row.nome}</td>
                                    <td>{row.cargo}</td>
                                    <td>{row.departamento}</td>
                                    <td><Styled.Icon src={Edit} alt="edit" /></td>
                                </tr>)
                            })
                        }
                    </tbody>
                </Styled.Table>
            </Styled.Card>
            <h1>Adicionar Usuários</h1>

            <Styled.Input placeholder='Nome' type="text"
                onChange={(e) => setUserCad({ ...userCad, nome: e.target.value })} />
            {(erroMessage.status && erroMessage.data.firstName) && <p className='error'>{erroMessage.data.firstName[0]}</p>}
            <Styled.Input placeholder='Sobrenome' type="text"
                onChange={(e) => setUserCad({ ...userCad, sobre_nome: e.target.value })} />
            {(erroMessage.status && erroMessage.data.lastName) && <p className='error'>{erroMessage.data.lastName[0]}</p>}
            <Styled.Input placeholder='Email' type="text"
                onChange={(e) => setUserCad({ ...userCad, email: e.target.value })} />
            {(erroMessage.status && erroMessage.data.email) && <p className='error'>{erroMessage.data.email[0]}</p>}
            <Styled.Input placeholder='Telefone' type="text"
                onChange={(e) => setUserCad({ ...userCad, telefone: e.target.value })} />
            {(erroMessage.status && erroMessage.data.phone) && <p className='error'>{erroMessage.data.phone[0]}</p>}
            <Styled.Input placeholder='Senha' type="password"
                onChange={(e) => setUserCad({ ...userCad, senha: e.target.value })} />
            {(erroMessage.status && erroMessage.data.password) && <p className='error'>{erroMessage.data.password[0]}</p>}
            <Styled.Input placeholder='Confirmação de Senha' type="password"
                onChange={(e) => setUserCad({ ...userCad, confirm: e.target.value })} />
            {(erroMessage.status && erroMessage.data.confirm) && <p className='error'>{erroMessage.data.confirm[0]}</p>}
            {(erroMessage.status && erroMessage.data === 'Cadastrado com sucesso') && <p className='error'>{erroMessage.data}</p>}
            <Styled.Button onClick={register}>Cadastrar</Styled.Button>
        </Styled.Box>

        {/* <Styled.Box item xs={12} lg={3}>
            <Styled.Card item>
                <h1>Departamentos</h1>
                <Styled.Table>
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Grupos</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataDepartments.map((row) => {
                                return (<tr key={row.nome}>
                                    <td>{row.nome}</td>
                                    <td className='grupos'>{row.grupos.map((dep, index) => {
                                        if (dep.length > 0) {
                                            if (index < 2) {
                                                return (<p key={`${dep}${index}`}>{dep}</p>)
                                            } else if (index === 2) {
                                                return ("...")
                                            } else { return '' }

                                        } return ''
                                    })}</td>
                                    <td><Styled.Icon src={EditIcon} alt="edit" /></td>
                                </tr>)
                            })
                        }
                    </tbody>
                </Styled.Table>
            </Styled.Card>
            <Grid item className='add_departamento'>
                <h1>Adicionar Departamento</h1>
                <Styled.Input placeholder='Nome' type="text" />
                <Styled.Input placeholder='Descrição' type="text" />
                <h2>Grupos de Usuários</h2>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Cobrança</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Atendimento</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Integração</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Marketing</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Tecnologia</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Finanças</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Agenda Mensal</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Atendimentos Agendados</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Atendimentos Realizados</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Financeiro</label>
                </div>
                
                <Styled.Button>Salvar</Styled.Button>
            </Grid>
        </Styled.Box>

        <Styled.Box item xs={12} lg={3}>
            <Styled.Card item>
                <h1>Grupo de Usuários</h1>
                <Styled.Table>
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataGroupUsers.map((name) => {
                                return (<tr key={name}>
                                    <td>{name}</td>
                                    <td><Styled.Icon src={EditIcon} alt="edit" /></td>
                                </tr>)
                            })
                        }
                    </tbody>
                </Styled.Table>
            </Styled.Card>
            <Grid item className='add_departamento'>
                <h1>Adicionar Grupo de Usuários</h1>
                <Styled.Input placeholder='Nome' type="text" />
                <Styled.Input placeholder='Descrição' type="text" />
                <h2>Permissões</h2>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Clientes Adicionar</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Cliente Editar</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Clientes Listar e ver</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Usuários adicionar</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Usuários Editar</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Usuários Listar e Ver</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Financeiro adicionar</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Financeiro Editar</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Financeiro Ver e listar</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Relatório Adicionar</label>
                </div>
                <div className='row'>
                    <input type="checkbox" />
                    <label>Relatório Editar</label>
                </div>
                
                <Styled.Button>Salvar</Styled.Button>
            </Grid>
        </Styled.Box> */}



    </Styled.Container>;
}

export default UserScreen;
