import React from 'react';
import * as Styled from './Styled'
import {
  View,
  // Confirm,
  EditOrange
} from '../../../../../assets/imgs/icons'

function index({ setScreen, setView }) {
  return <Styled.Row>
      <Styled.Icon src={View} alt="view" className="eye" onClick={() =>  setView(true)} />
      {/* <Styled.Icon src={ConfirmarIcon} alt="check" className="check" /> */}
      <Styled.Icon src={EditOrange} alt="edit" className="edit" />
  </Styled.Row>;
}

export default index;
