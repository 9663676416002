import React from 'react';
import * as Styled from './Styled'
import {
  View,
  // Confirm,
  // EditOrange
} from '../../../../../assets/imgs/icons'

function Actions({ setModal }) {
  return <Styled.Row>
      <Styled.Icon src={View} alt="view" className="eye" onClick={() => setModal(true)} />
      {/* <Styled.Icon src={Confirm} alt="check" className="check" />
      <Styled.Icon src={EditOrange} alt="edit" className="edit" onClick={() => setScreen('Edit')} /> */}
  </Styled.Row>;
}

export default Actions;
