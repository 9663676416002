const columns = [
    {
        name: "nome",
        label: "Nome",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
                return (
                    <b>{value}</b>
                );
            }
        }
    },
    {
        name: "data_cadastro",
        label: "Data de cadastro",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "action",
        label: "Ações",
        options: {
            filter: true,
            sort: false,
        }
    },
];

const options = {
    filter: false,
    download: false,
    // print: false,
    filterType: 'checkbox',
    selectableRows: 'none',
    viewColumns: false,
};


export { columns, options }