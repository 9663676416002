import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'

const Grid = styled(MuiGrid)`
  width: 100%;
  height: 100%;
  background: url('/bg.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled.h1`
    font-size: 18px;
    font-weight: bolder;
`

const Container = styled.div`
    margin: 0 auto;
    width: 500px;
    padding: 30px;
    border-radius: 25px;
`

const Card = styled.div`
    background: #fff;
    text-align: center;
    border-radius: 15px;
    perspective: 1000px;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    // &:hover{
    //     transform: rotateX(-180deg);
    // }
`

const Header = styled.div`
    text-align: center;
    padding: 20px;
    
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 20px;
`

const Input = styled.input`
    width: 96%;
    border: 2px solid #F2F2F2;
    border-radius: 10px;
    padding: 15px;
    transition:all 0.3s ease;
`

const Content = styled.div`
    text-align: center;
    padding: 20px;
    background: #f9f9fa;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    .email{margin-bottom: 5px;}
`

const Button = styled.button`
    width: 60%;    
    margin: 0 auto;
    padding: 15px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 30px;
    transition:all 0.5s ease;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #ff6228;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        font-style: italic;
    }
`

const Esqueci = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    color: #FF4500;
    font-weight: bold;
    .mr-5{margin-right:5px;}
    cursor: pointer;
    width: max-content;
    margin-top: 15px;
`

const Logo = styled.img`
    width: 50%;
    margin: 0 auto;
`

const Footer = styled.p`
    font-family: "SFProDisplay";
    font-weight: 300;
    font-style: italic;
    color: #FFFFFF;
    font-size: 18px;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    margin-top: 15px;
`


export { Grid, Container, Card, Header, Content, Title, Logo, Button, Esqueci, Footer, Input }
