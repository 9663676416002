import styled from 'styled-components'
import Grid from '@mui/material/Grid';

const Container = styled(Grid)`
  position: relative;
  display: flex;
  width: 98%;
  margin: 0 auto;
  /* Grey 7 */
  background: #FAFAFA;
  /* Gray 5 */
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 30px;
  
  .item{
    margin-bottom: 10px;
    input:nth-child(n+3){
      margin-top: 10px;
    }
    input::placeholder{
      color: #BDBDBD;
    }
    textarea::placeholder{
      color: #BDBDBD;
    }
  }

  input:focus {
    outline: none !important;
    border: 1px solid #f64300;
  }
  textarea:focus {
    outline: none !important;
    border: 1px solid #f64300;
  }
`
const Title = styled.h1`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  color: var(--ommed);
`

const Input = styled.input`
  width: 98%;
  padding: 10px;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 183% */
  letter-spacing: -0.408px;
  /* Gray 4 */
  color: #BDBDBD;
  transition:all 0.3s ease;  
`
const TextArea = styled.textarea`
  width: 99%;
  max-height: 98px;
  padding: 10px;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 183% */
  letter-spacing: -0.408px;
  /* Gray 4 */
  color: #BDBDBD;
`

const Button = styled.button`
    width: 180px;    
    height: 40px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;

    color: #fff;
    font-weight: bolder;
    background: #f64300;  /* fallback for old browsers */
    background: var(--gradiente-main-wk);  /* Chrome 10-25, Safari 5.1-6 */
    background: var(--gradiente-main); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
        background: #ff6228;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #ff6228, #f64300);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #ff6228, #f64300); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
`

export { Container, Title, Input, TextArea, Button }